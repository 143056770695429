window.tokens = function ()
{

    return {

        add_tokens_modal: false,
        spend_tokens_modal: false,
        details_modal: false,

        init()
        {
            this.get_tokens();
        },

        get_tokens()
        {
            let scope = this;
            axios.get('/employees/agent/get_tokens')
                .then(function (response)
                {
                    scope.$refs.tokens_div.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        show_add_tokens(agent_id, tokens, name)
        {
            let scope = this;

            scope.add_tokens_modal = true;

            scope.$refs.add_agent_id.value = agent_id;
            scope.$refs.add_agent_name.innerText = name;

        },

        add_tokens(ele, type)
        {
            let scope = this;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');

            let form = type == 'add' ? scope.$refs.add_tokens_form : scope.$refs.spend_tokens_form;
            let formData = new FormData(form);

            axios.post('/employees/agent/add_tokens', formData)
                .then(function (response)
                {

                    scope.get_tokens();
                    if (type == 'add')
                    {
                        notify('success', 'Tokens added successfully');
                        scope.add_tokens_modal = false;
                    } else
                    {
                        notify('success', 'Tokens spent successfully');
                        scope.spend_tokens_modal = false;
                    }
                    ele.innerHTML = button_html;
                })
                .catch(function (error)
                {
                });
        },

        show_spend_tokens(agent_id, tokens, name)
        {
            let scope = this;

            scope.spend_tokens_modal = true;

            scope.$refs.spend_agent_id.value = agent_id;
            scope.$refs.spend_agent_name.innerText = name;
        },


        show_details(agent_id)
        {
            let scope = this;

            scope.details_modal = true;

            axios.get('/employees/agent/get_token_details', {
                params: {
                    agent_id: agent_id
                },
            })
                .then(function (response)
                {
                    scope.$refs.details_div.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });

        },

    }

}
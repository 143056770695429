
if(document.URL.match(/loan_officer$/)) {


    window.employees = function() {

        return {


        }


    }


}

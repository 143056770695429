window.licenses = function (categories, license_id, license_type)
{

    return {

        categories: categories,
        license_id: license_id,
        license_type: license_type,
        active_tab: 'company_licenses',
        show_item_modal: false,
        show_notes_and_uploads: false,
        show_add_notes: false,
        show_notes_modal: false,
        show_uploads_modal: false,
        show_add_upload: false,
        show_deleted: false,
        show_agent_matches: false,
        // show_license_stats: false,
        // active_stat: '',
        item_category: '',

        init()
        {

            let scope = this;

            for (let c in categories)
            {
                let category = categories[c].tag;
                scope.get_licenses(category);
            }

            scope.add_text_editor();
            scope.get_license_stats();

            if (scope.license_id)
            {
                scope.show_item(scope.license_id);
                scope.active_tab = license_type;
            }

        },

        get_licenses(category, scroll_to = null, row = null)
        {

            axios.get('/licenses/get_company_licenses_html', {
                params: {
                    category: category
                },
            })
                .then(function (response)
                {
                    document.querySelector('.' + category + '-div').innerHTML = response.data;
                    if (scroll_to)
                    {
                        setTimeout(function ()
                        {
                            document.documentElement.scrollTop = document.body.scrollTop = scroll_to;
                            if (document.querySelector(row))
                            {
                                document.querySelector(row).classList.add('bg-gray-100');
                            }
                        }, 300);
                    }
                })
                .catch(function (error)
                {

                });

        },

        get_license_stats()
        {
            let scope = this;
            let page = 'company_licenses';
                if (document.URL.match(/(dev|com)\/dashboard/))
                {
                    page = 'dashboard';
                }
            axios.get('/licenses/get_license_stats', { params: { page: page } })
                .then(function (response)
                {
                    if (response.data)
                    {
                        scope.$refs.license_stats_div.innerHTML = response.data;
                        // scope.show_license_stats = true;
                    }
                })
                .catch(function (error)
                {

                });
        },

        show_item(license_id = null)
        {

            let scope = this;

            scope.show_item_modal = true;
            scope.clear_form();
            remove_form_errors();

            if (license_id)
            {
                scope.show_notes_and_uploads = true;
                // get and add data
                axios.get('/licenses/get_item_data', {
                    params: {
                        license_id: license_id
                    },
                })
                    .then(function (response)
                    {

                        scope.$refs.license_id.value = license_id;

                        let license = response.data.license;
                        
                        for (const key in license)
                        {
                            if (document.querySelector('[name="' + key + '"]'))
                            {
                                if (key != 'counties')
                                {
                                    document.querySelector('[name="' + key + '"]').value = license[key];
                                } else
                                {
                                    if (license[key] != null)
                                    {
                                        let counties = JSON.parse(license[key]);
                                        let counties_select = scope.$refs.counties;
                                        for (let i = 0; i < counties_select.options.length; i++)
                                        {
                                            counties_select.options[i].selected = counties.indexOf(counties_select.options[i].value) >= 0;
                                        }
                                    }
                                }
                            }
                            if (key == 'category_name')
                            {
                                scope.$refs.item_category.innerText = license[key];
                                scope.item_category = license[key].replace(/s$/, '');
                            }
                        }

                        scope.get_uploads(license_id);
                        scope.get_notes(license_id);

                        scope.show_deleted = false;

                        setTimeout(function ()
                        {
                            scope.show_counties();
                        }, 200);

                    })
                    .catch(function (error)
                    {

                    });

            } else
            {
                scope.show_notes_and_uploads = false;
                scope.$refs.category.value = scope.active_tab;
            }
        },

        save_item(ele)
        {

            let scope = this;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let scroll_to = document.documentElement.scrollTop || document.body.scrollTop;

            let form = document.getElementById('license_form');
            let formData = new FormData(form);

            let counties = [...scope.$refs.counties.selectedOptions].map(o => o.value);
            formData.append('counties', JSON.stringify(counties));
            axios.post('/licenses/save_item', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    let row = '.row-' + scope.$refs.license_id.value;
                    scope.get_licenses(scope.active_tab, scroll_to, row);
                    notify('success', 'License saved successfully');
                    scope.show_item_modal = false;
                    // scope.show_license_stats = false;
                    scope.clear_form();
                    scope.get_license_stats();
                    // let prev_active_stat = scope.active_stat;
                    // scope.active_stat = '';
                    // scope.active_stat = prev_active_stat;

                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        delete_item(ele)
        {
            if (confirm('Are you sure you want to permanently delete this item?'))
            {

                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Deleting ... ');
                remove_form_errors();

                let license_id = scope.$refs.license_id.value;
                let formData = new FormData();
                formData.append('license_id', license_id);

                axios.post('/licenses/delete_item', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'License deleted successfully');
                        scope.get_licenses(scope.active_tab);
                        scope.show_item_modal = false;
                        scope.get_license_stats();
                        // let prev_active_stat = scope.active_stat;
                        // scope.active_stat = '';
                        // scope.active_stat = prev_active_stat;
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });

            }

        },

        clear_form()
        {
            let scope = this;
            scope.$refs.license_id.value = '';
            scope.$refs.license_form.reset();
        },

        // show_uploads(license_id)
        // {

        //     let scope = this;

        //     scope.show_uploads_modal = true;
        //     scope.get_uploads(license_id);
        //     scope.$refs.license_id_upload.value = license_id;

        // },

        get_uploads(license_id)
        {

            let scope = this;

            axios.get('/licenses/get_uploads', {
                params: {
                    license_id: license_id,
                },
            })
                .then(function (response)
                {
                    scope.$refs.uploads_div.innerHTML = response.data;
                    scope.$refs.license_id_upload.value = license_id;
                })
                .catch(function (error)
                {

                });
        },

        add_upload(ele)
        {

            let scope = this;
            let license_id = scope.$refs.license_id_upload.value;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Uploading File ... ');

            let form = scope.$refs.add_upload_form;
            let formData = new FormData(form);
            formData.append('license_id', license_id);

            axios.post('/licenses/add_upload', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Upload Successfully Added');
                    scope.get_uploads(license_id);
                    scope.show_add_upload = false;
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                    ele.innerHTML = button_html;
                });
        },

        delete_upload(ele, license_id, upload_id)
        {

            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Deleting ... ');

            let formData = new FormData();
            formData.append('upload_id', upload_id);

            axios.post('/licenses/delete_upload', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Upload successfully deleted');
                    scope.get_uploads(license_id);
                })
                .catch(function (error)
                {
                });
        },

        activate_upload(ele, license_id, upload_id)
        {

            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Reactivating ... ');

            let formData = new FormData();
            formData.append('upload_id', upload_id);

            axios.post('/licenses/reactivate_upload', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Upload successfully reactivated');
                    scope.get_uploads(license_id);
                    scope.show_deleted = false;
                })
                .catch(function (error)
                {
                });
        },

        // show_notes(license_id)
        // {

        //     let scope = this;

        //     scope.show_notes_modal = true;
        //     scope.get_notes(license_id);

        // },

        get_notes(license_id)
        {
            let scope = this;
            axios.get('/licenses/get_notes', {
                params: {
                    license_id: license_id
                },
            })
                .then(function (response)
                {
                    scope.$refs.notes_div.innerHTML = response.data;
                    scope.$refs.license_id_notes.value = license_id;
                })
                .catch(function (error)
                {

                });

        },

        add_notes(ele)
        {

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let scope = this;
            let formData = new FormData();
            let license_id = scope.$refs.license_id_notes.value;
            formData.append('license_id', license_id);
            formData.append('notes', tinymce.activeEditor.getContent());

            axios.post('/licenses/add_notes', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_notes(license_id);
                    if (enable_text_editor_in_development == true)
                    {
                        tinymce.activeEditor.setContent('');
                    }
                    notify('success', 'Note Saved');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        delete_note(ele, id)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, '');
            remove_form_errors();

            let formData = new FormData();
            let license_id = scope.$refs.license_id_notes.value;
            formData.append('id', id);

            axios.post('/licenses/delete_note', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_notes(license_id);
                    notify('success', 'Note Deleted');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        add_text_editor()
        {

            let options = {
                selector: '.add-notes',
                height: '300',
                statusbar: false,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'image', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'autoresize'
                ],
                toolbar: 'undo redo bold italic | forecolor backcolor | image | bullist numlist outdent indent',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                relative_urls: false,
                document_base_url: location.hostname,
            }
            text_editor(options);

        },

        search_agents(input)
        {

            let scope = this;

            let val = input.value;
            if (val != '')
            {
                scope.show_agent_matches = true;
                let matched = false;
                document.querySelectorAll('.agent-matches').forEach(function (ele)
                {
                    if (ele.innerText.toLowerCase().indexOf(val.toLowerCase()) > -1)
                    {
                        ele.classList.remove('hidden');
                        ele.addEventListener('click', function ()
                        {
                            input.value = ele.innerText;
                        });
                        matched = true;
                    } else
                    {
                        ele.classList.add('hidden');
                    }
                });
                if (matched == false)
                {
                    scope.show_agent_matches = false;
                }
            } else
            {
                scope.show_agent_matches = false;
            }
        },

        show_counties()
        {
            let scope = this;
            let counties = [...scope.$refs.counties.selectedOptions].map(o => o.value);
            scope.$refs.selected_counties.innerHTML = counties.join('<br>');
        },

    }

}

window.tabs = function (categories)
{

    return {

        selectedId: null,

        init()
        {

            let scope = this;
            scope.$nextTick(() => this.select(this.$id('tab', 1)));

        },


        select(id)
        {
            this.selectedId = id
        },
        isSelected(id)
        {
            return this.selectedId === id
        },
        whichChild(el, parent)
        {
            return Array.from(parent.children).indexOf(el) + 1
        },

    }

}


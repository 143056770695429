if(document.URL.match(/loans/)) {


    window.loans = function() {

        return {



        }

    }

}

window.report_problems = function ()
{

    return {

        show_view_transaction: false,

        init()
        {

        },

        update_review_status(ele)
        {
            let review_status = ele.value;
            let id = ele.getAttribute('data-id');
            let url = '/company_accounts/user_actions/update_review_status';
            let data = {
                review_status: review_status,
                id: id,
            };
            axios.post(url, data)
                .then(response =>
                {
                    notify('success', 'Review status updated');
                })
                .catch(error =>
                {
                    console.log(error);
                });
        },

        claim_transaction(ele, transaction_id)
        {
            let url = '/company_accounts/user_actions/claim_transaction';
            let user_id = this.$refs.user_id.value;
            let data = {
                claimed: ele.value,
                user_id: user_id,
                transaction_id: transaction_id,
            };
            axios.post(url, data)
                .then(response =>
                {
                    notify('success', 'Transaction Updated');
                })
                .catch(error =>
                {
                    console.log(error);
                });
        },

        view_transaction(transaction_id, disabled = false)
            {

                let scope = this;
                let transactions_div = scope.$refs.view_transaction_div;
                scope.show_view_transaction = true;

                axios.get('/company_accounts/view_transaction', { params: { transaction_id: transaction_id, disabled: disabled } })
                    .then(function (response)
                    {
                        transactions_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },

    }

}




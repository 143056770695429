window.hubspot_settings = function ()
{

    return {

        init()
        {

        },

        update_setting(ele, setting, value)
        {
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');

            let formData = new FormData();
            formData.append('setting', setting);
            formData.append('value', value);

            axios.post('/resources/settings/save_setting', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Setting saved successfully');
                })
                .catch(function (error)
                {
                });

        }

    }

}
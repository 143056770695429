if (document.URL.match(/hubspot/))
{

    window.hubspot = function ()
    {

        return {

            init()
            {
                this.get_emails();
            },

            get_emails()
            {
                let scope = this;
                let businesses = ['tp', 'ht', 'hf', 'ih'];
                businesses.forEach(function (business)
                {
                    axios.get('/hubspot/get_emails', {
                        params: {
                            business: business
                        },
                    })
                        .then(function (response)
                        {
                            document.querySelector('#' + business).innerHTML = response.data;
                            document.querySelector('#show_new_clones').classList.add('hidden');
                            document.querySelector('#show_new_clones').classList.remove('flex');
                        })
                        .catch(function (error)
                        {

                        });
                });
            },

            fetch_emails(ele)
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Fetching new emails ... ');
                setTimeout(function ()
                {
                    axios.get('/hubspot/get_new_emails')
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                        });
                }, 6000);

            },

            show_options(table)
            {
                let checkboxes = table.querySelectorAll('.email:checked');
                if (checkboxes.length > 0)
                {
                    this.show_options_div = true;
                } else
                {
                    this.show_options_div = false;
                    check_all().checked = false;
                }
            },

            show_clone_emails(table)
            {
                let emails = table.querySelectorAll('.email:checked');
                let ids = [];
                [...emails].map((el) =>
                {
                    ids.push(el.getAttribute('data-id'));
                });
                ids = ids.reverse();
                ids = JSON.stringify(ids);

                axios.get('/hubspot/get_cloned_emails', {
                    params: {
                        ids: ids
                    },
                })
                    .then(function (response)
                    {
                        let email_div = table.closest('.email-div');
                        email_div.querySelector('.clones-div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },

            update_clones(ele)
            {
                let clone_div = ele.closest('.clones-div');
                let new_send_date = clone_div.querySelector('.new-send-date-input').value;
                let new_subject = clone_div.querySelector('.new-subject-input').value;
                let new_preview_text = clone_div.querySelector('.new-preview-text-input').value;
                let old_name = clone_div.querySelector('.old-name-input').value;
                let new_name = clone_div.querySelector('.new-name-input').value;
                let send_date_divs = clone_div.querySelectorAll('.send-date');
                let subject_divs = clone_div.querySelectorAll('.subject');
                let preview_text_divs = clone_div.querySelectorAll('.preview-text');
                let name_divs = clone_div.querySelectorAll('.name');

                if (new_send_date != '')
                {
                    send_date_divs.forEach(function (send_date_div)
                    {
                        send_date_div.innerText = new_send_date;

                        var d = new Date(new_send_date);
                        d.setDate(d.getDate() + 1);
                        new_send_date = d.toISOString().split('T')[0];
                    });
                }

                if (new_subject != '')
                {
                    subject_divs.forEach(function (subject_div)
                    {
                        subject_div.innerText = new_subject;
                    });
                } else
                {
                    subject_divs.forEach(function (subject_div)
                    {
                        subject_div.innerText = subject_div.getAttribute('data-default-value');
                    });
                }

                if (new_preview_text != '')
                {
                    preview_text_divs.forEach(function (preview_text_div)
                    {
                        preview_text_div.innerText = new_preview_text;
                    });
                } else
                {
                    preview_text_divs.forEach(function (preview_text_div)
                    {
                        preview_text_div.innerText = preview_text_div.getAttribute('data-default-value');
                    });
                }

                if (old_name != '' && new_name != '')
                {
                    name_divs.forEach(function (name_div)
                    {
                        let prev_name = name_div.getAttribute('data-default-value');
                        let name = prev_name.replace(old_name, new_name);
                        name_div.innerText = name;
                    });
                } else
                {
                    name_divs.forEach(function (name_div)
                    {
                        name_div.innerText = name_div.getAttribute('data-default-value');
                    });
                }
            },

            create_clones(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Creating Clones ... ');

                let clone_div = ele.closest('.clones-div');
                let group_send_date = clone_div.querySelector('.new-send-date-input').value;
                let email_rows = clone_div.querySelectorAll('.email-row');
                let emails = [];
                email_rows.forEach(function (row)
                {
                    let data = {
                        'hubspot_id': row.getAttribute('data-hubspot-id'),
                        'business': row.getAttribute('data-hubspot-business'),
                        'send_date': row.querySelector('.send-date').innerText,
                        'name': row.querySelector('.name').innerText,
                        'subject': row.querySelector('.subject').innerText,
                        'preview_text': row.querySelector('.preview-text').innerText
                    }
                    emails.push(data);
                });
                emails = JSON.stringify(emails);

                let formData = new FormData();
                formData.append('group_send_date', group_send_date);
                formData.append('emails', emails);

                axios.post('/hubspot/create_clones', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_emails();
                        notify('success', 'Emails successfully cloned');

                        let ids = response.data;
                        broadcast_add('hubspot_emails', 'hubspot_id', ids, '#show_new_clones');
                    })
                    .catch(function (error)
                    {
                        ele.innerHTML = button_html;
                    });

            },

        }

    }

    window.create_hubspot_emails = function ()
    {

        return {

            init()
            {

            },

            get_hubspot_emails(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Getting Emails ... ');
                remove_form_errors();

                let form = scope.$refs.get_emails_form;
                let formData = new FormData(form);

                axios.post('/hubspot/create_emails/get_hubspot_emails', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.$refs.emails_div.innerHTML = response.data;

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

        }

    }

}
window.billing_hf = function ()
{

    return {

        init: function ()
        {
            this.get_billing_settings();
        },

        get_billing_settings()
        {
            let scope = this;
            axios.get('/employees/mortgage/get_billing_settings')
                .then(function (response)
                {
                    document.getElementById('billing_settings_div').innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });

        },

        save_billing_settings(ele)
        {
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = document.getElementById('billing_settings_form');
            let formData = new FormData(form);

            axios.post('/employees/mortgage/save_billing_settings', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Settings saved successfully.');

                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

    }

}
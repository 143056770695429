if(document.URL.match(/archived/)) {

    window.archives = function() {

        return {


        }

    }

}

window.data_tables = function (table_options)
{

    // options saved so table can be reloaded

    if (!table_options)
    {
        table_options = JSON.parse(localStorage.getItem('table_options'));
    } else
    {
        localStorage.setItem('table_options', JSON.stringify(table_options));
    }


    return {

        container: table_options.container,
        data_url: table_options.data_url,
        page: table_options.page || 1,
        sort_by: table_options.sort_by || null,
        sort_dir: table_options.sort_dir || 'asc',
        length: table_options.length || '10',
        check_all: table_options.check_all || false,
        filters: [],
        function: table_options.function || null,

        init()
        {

            let scope = this;
            scope.create_table();

        },

        create_table()
        {

            let scope = this;
            let url = scope.data_url;

            let formData;
            let filters_div = document.querySelector('#table_filter_div');

            if (filters_div)
            {
                formData = new FormData(filters_div);
            } else
            {
                formData = new FormData();
            }

            formData.append('page', scope.page);
            formData.append('sort_by', scope.sort_by);
            formData.append('sort_dir', scope.sort_dir);
            formData.append('length', scope.length);
            formData.append('check_all', scope.check_all);

            axios.post(url, formData)
                .then(function (response)
                {
                    let container = document.querySelector(scope.container);
                    container.innerHTML = '';

                    container.insertAdjacentHTML('beforeend', response.data);

                    document.querySelectorAll('.table-link').forEach(function (table_link)
                    {

                        table_link.addEventListener('click', function (e)
                        {

                            e.preventDefault();
                            let href = table_link.getAttribute('href');
                            let page_match = href.match(/page=([0-9]+)/);
                            scope.page = page_match[1];
                            table_options.page = page_match[1];

                            localStorage.setItem('table_options', JSON.stringify(table_options));

                            scope.create_table();

                        });

                    });

                    document.querySelectorAll('.sort-by-link').forEach(function (link)
                    {
                        link.addEventListener('click', function (e)
                        {
                            e.preventDefault();
                            let sort_dir = 'asc';
                            if (scope.sort_dir)
                            {
                                if (scope.sort_dir == 'asc')
                                {
                                    sort_dir = 'desc';
                                } else
                                {
                                    sort_dir = 'asc';
                                }
                                scope.sort_dir = sort_dir;
                            }
                            scope.sort_by = link.getAttribute('data-sort-by');
                            table_options.sort_by = link.getAttribute('data-sort-by');
                            table_options.sort_dir = sort_dir;
                            localStorage.setItem('table_options', JSON.stringify(table_options));

                            scope.create_table();

                        });
                    });

                    let length_select = document.getElementById('pagination_length');
                    if (length_select)
                    {
                        length_select.addEventListener('change', function ()
                        {
                            scope.length = length_select.value;
                            scope.page = 1;
                            table_options.length = scope.length;
                            table_options.page = 1;
                            localStorage.setItem('table_options', JSON.stringify(table_options));

                            scope.create_table();
                        });
                    }

                    if (scope.check_all == true)
                    {
                        let th = container.querySelector('tr th:first-child');
                        let checkbox = '<input type="checkbox" id="table_check_all" class="form-element primary lg checkbox"  @change="data_tables().check_all_boxes($el)">';
                        th.insertAdjacentHTML('beforeend', checkbox);
                    }

                    let count = container.querySelector('table').rows[0].cells.length;
                    let total_records = document.getElementById('table_total_records').value;
                    let delete_div = ' \
                        <tr> \
                        <td colspan="' + count + '"> \
                            <div class="hidden" id="table_delete_div"> \
                                <div class="p-2 font-semibold bg-gray-50 border-b flex items-center space-x-4"> \
                                    <div><span id="table_delete_count"></span> Records Selected</div> \
                                    <div>|</div> \
                                    <div>Include All ' + total_records + ' Records?</div> \
                                    <input type="checkbox" id="table_delete_all" class="form-element primary sm checkbox"> \
                                    <button type="button" class="button danger sm" @click="delete_items($el)" href="javascript:void(0)">Delete</button> \
                                </div> \
                            </div> \
                        </td> \
                    </tr> \
                    ';

                    container.querySelector('table thead').insertAdjacentHTML('beforeend', delete_div);

                    document.querySelector(scope.container).querySelectorAll('[type="checkbox"]').forEach(function (check)
                    {
                        check.addEventListener('click', function ()
                        {
                            scope.show_delete_div()
                        });
                    });

                    // run extra function
                    if (scope.function)
                    {
                        scope.function;
                    }

                })
                .catch(function (error)
                {

                });

        },

        check_all_boxes(ele)
        {
            let scope = this;
            document.querySelector(scope.container).querySelectorAll('[type="checkbox"]').forEach(function (check)
            {
                if (check != ele && check.id != 'table_delete_all')
                {
                    check.checked = ele.checked;
                }
            });
            scope.show_delete_div();
        },

        show_delete_div()
        {
            let scope = this;
            let delete_div = document.getElementById('table_delete_div');
            let show = false;
            let count_checked = 0;
            document.querySelector(scope.container).querySelectorAll('[type="checkbox"]').forEach(function (check)
            {
                if (check.id != 'table_delete_all')
                {
                    if (check.checked)
                    {
                        show = true;
                        if (check.id != 'table_check_all')
                        {
                            count_checked += 1;
                        }
                    }
                }
            });

            document.getElementById('table_delete_count').innerText = count_checked;

            if (show == true)
            {
                delete_div.classList.remove('hidden');
            } else
            {
                delete_div.classList.add('hidden');
            }
        }

    }

}
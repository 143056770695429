window.email_agents = function ()
{

    return {

    }

}

window.select_template = function ()
{

    return {
        selected_template: '',
        template_selected: false,
    }

}

window.create_email = function ()
{

    return {

        notes_editor()
        {

            let options = {
                selector: '.editor-inline',
                inline: true
                // max_height: 700,
                // width: '100%',
                // statusbar: false,
                // quickbars_selection_toolbar: 'bold italic link bullist numlist',
                // plugins: [
                //     'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                //     'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                //     'insertdatetime', 'media', 'table', 'help', 'autoresize', 'quickbars'
                // ],
                // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                // table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                // relative_urls: false,
                // document_base_url: location.hostname,
            }
            text_editor(options);

        },

    }

}

window.images = function ()
{
    return {

        add_image_modal: false,
        show_results: false,
        add_images_folders_modal: false,
        show_check_all_options: false,
        active_folder: '',

        init()
        {
            this.crop();
            this.get_images();
            this.get_folders();
        },

        crop()
        {
            let scope = this;
            let image = document.getElementById('image_cropper_result');
            const pixo = new Pixo.Bridge({
                apikey: '7ni4b3s8jzk0',
                theme: 'dark',
                'width': '90%',
                'height': '90%',
                onSave: function (arg)
                {
                    scope.show_results = true;
                    image.src = arg.toDataURL();
                }
            });
            pixo.attachToFileInput(document.querySelector('input[type=file]'));

        },

        get_images(folder_id = null)
        {
            this.active_folder = folder_id;
            this.reset_check_all();
            axios.get('/heritage_financial/email_agents/get_images', {
                params: {
                    folder_id: folder_id
                },
            })
                .then(function (response)
                {
                    document.querySelector('#images_div').innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        show_options_div()
        {
            let checked = false;
            if (document.querySelectorAll('.image-checkbox:checked').length > 0)
            {
                checked = true;
            }
            this.show_check_all_options = checked;
        },

        delete_all_checked(ele, folder_id = null)
        {
            if (confirm('Are you sure you want to delete the selected images?'))
            {
                let scope = this;
                let inputs = document.querySelectorAll('.image-checkbox:checked');
                let ids = [];
                inputs.forEach(function (input)
                {
                    ids.push(input.getAttribute('data-id'));
                });

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Deleting ... ');

                let formData = new FormData();
                formData.append('ids', ids);
                formData.append('folder_id', folder_id);

                axios.post('/heritage_financial/email_agents/delete_images', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_images(scope.active_folder);
                        scope.show_check_all_options = false;
                        scope.reset_check_all();
                    })
                    .catch(function (error)
                    {
                    });
            }

        },

        move_all_checked(ele, folder_id)
        {
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Moving ... ');

            let scope = this;
            let inputs = document.querySelectorAll('.image-checkbox:checked');
            let ids = [];
            inputs.forEach(function (input)
            {
                ids.push(input.getAttribute('data-id'));
            });

            let formData = new FormData();
            formData.append('folder_id', folder_id);
            formData.append('ids', ids);

            axios.post('/heritage_financial/email_agents/move_all_checked', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_images(folder_id);
                    scope.show_check_all_options = false;
                    scope.reset_check_all();
                })
                .catch(function (error)
                {
                });
        },

        save_add_image(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = document.getElementById('add_image_form');
            let formData = new FormData(form);

            axios.post('/heritage_financial/email_agents/save_add_image', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.add_image_modal = false;
                    scope.get_images();
                    scope.get_images(scope.active_folder);
                    scope.show_check_all_options = false;
                    scope.reset_check_all();
                    notify('success', 'Image Successfully Added');

                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        delete_image(ele, image_id)
        {
            let scope = this;
            if (confirm('Are you sure you want to permanently delete this image?'))
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Deleting ... ');

                let formData = new FormData();
                formData.append('image_id', image_id);

                axios.post('/heritage_financial/email_agents/delete_image', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_images(scope.active_folder);
                    })
                    .catch(function (error)
                    {
                    });
            }
        },

        update_image_public_status(image_id, public_status)
        {
            let formData = new FormData();
            formData.append('image_id', image_id);
            formData.append('public_status', public_status);

            axios.post('/heritage_financial/email_agents/update_image_public_status', formData)
                .then(function (response)
                {



                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        edit_image_title(image_id, title)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('image_id', image_id);
            formData.append('title', title);

            axios.post('/heritage_financial/email_agents/edit_image_title', formData)
                .then(function (response)
                {
                    notify('success', 'Name Successfully Changed');
                    scope.get_images(scope.active_folder);
                })
                .catch(function (error)
                {
                });
        },

        get_folders()
        {
            axios.get('/heritage_financial/email_agents/get_folders_for_select')
                .then(function (response)
                {
                    let folders = response.data.folders;

                    document.querySelectorAll('[name="folder_id"]').forEach(function (select)
                    {
                        for (var i in folders)
                        {
                            var id = folders[i].id;
                            var title = folders[i].title;
                            var option = document.createElement('option');
                            option.value = id;
                            option.text = title;
                            select.add(option);
                        }
                        select.closest('div').querySelector('label').remove();
                        select.classList.remove('label-added');
                    });
                })
                .catch(function (error)
                {

                });
        },

        edit_folder_title(folder_id, title)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('folder_id', folder_id);
            formData.append('title', title);

            axios.post('/heritage_financial/email_agents/edit_folder_title', formData)
                .then(function (response)
                {
                    notify('success', 'Name Successfully Changed');
                    scope.get_images();
                })
                .catch(function (error)
                {
                });
        },

        update_folder_public_status(folder_id, public_status)
        {
            let formData = new FormData();
            formData.append('folder_id', folder_id);
            formData.append('public_status', public_status);

            axios.post('/heritage_financial/email_agents/update_folder_public_status', formData)
                .then(function (response)
                {



                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        save_add_images_folders(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = document.getElementById('add_images_folders_form');
            let formData = new FormData(form);

            axios.post('/heritage_financial/email_agents/save_add_images_folders', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.add_images_folders_modal = false;
                    scope.get_images();
                    notify('success', 'Folder Successfully Added');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        delete_folder(ele, folder_id)
        {
            if (confirm('Are you sure you want to permanently delete this folder?'))
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Deleting ... ');

                let formData = new FormData();
                formData.append('folder_id', folder_id);

                axios.post('/heritage_financial/email_agents/delete_folder', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        ele.closest('.folder-div').remove();
                    })
                    .catch(function (error)
                    {
                    });
            }
        },

        reset_check_all()
        {
            document.getElementById('check_all').checked = false;
            this.show_check_all_options = false;
        },
    }

}

if (document.URL.match(/plus_minus_agents/))
{
    window.plus_minus_agents = function ()
    {
     
        return {

            init()
            {
                ele_loading(document.getElementById('plus_minus_agents'));
                this.get_plus_minus_agents();
                this.chart_agent_counts();
            },

            get_plus_minus_agents()
            {
                let scope = this;
                let plus_minus = document.getElementById('plus_minus_agents');

                axios.get('/reports/real_estate/get_plus_minus_agents')
                .then(function (response) {
                    plus_minus.innerHTML = response.data;
                    let div = document.getElementById('plus_minus_agents_div');
                    div.scrollLeft = div.scrollWidth;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            chart_agent_counts(company = null)
            {

                axios.get('/reports/real_estate/chart_agent_counts', {
                    params: {
                        company: company
                    },
                })
                    .then(function (response)
                    {
                        let agents_data = response.data.agents_data;

                        let month_names = response.data.month_names;
                        const chart = Highcharts.chart('chart_agent_counts', {
                            chart: {
                                type: 'line',
                                height: 400
                            },
                            title: {
                                text: '',
                            },
                            xAxis: {
                                categories: month_names
                            },
                            yAxis: {
                                title: {
                                    text: 'Agent Count'
                                }
                            },
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'top',
                                reversed: true
                            },
                            plotOptions: {
                                series: {
                                    allowPointSelect: true
                                }
                            },
                            series: agents_data,
                            responsive: {
                                rules: [{
                                    condition: {
                                        maxWidth: 500
                                    },
                                    chartOptions: {
                                        legend: {
                                            layout: 'horizontal',
                                            align: 'center',
                                            verticalAlign: 'bottom'
                                        }
                                    }
                                }]
                            }
                        });
                    })

                    .catch(function (error)
                    {

                    });

            },

        }

    }


}
window.lending_pad_loans = function ()
{

    return {

        init()
        {
            let scope = this;
            scope.get_loans();
            setInterval(() =>
            {
                scope.get_loans();
            }, 3000);
        },

        get_loans()
        {
            let scope = this;
            axios.get('/tools/marketing/lending_pad/get_loans')
                .then(function (response)
                {
                    scope.$refs.loans_div.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        }

    }

}
window.chat_gpt = function ()
{

    return {

        type_selected: 'chat',
        confirm_image_search: false,
        request_id: new Date().getTime(),

        init()
        {
            this.get_requests();
        },

        get_requests()
        {
            let scope = this;
            axios.get('/chat_gpt/get_requests')
                .then(function (response)
                {
                    if (response.data != '')
                    {
                        document.getElementById('get_requests_div').innerHTML = response.data;
                        setTimeout(function ()
                        {
                            scope.scroll_div();
                        }, 500);
                    }
                })
                .catch(function (error)
                {

                });
        },

        get_request(request_id)
        {
            let scope = this;
            scope.request_id = request_id;
            axios.get('/chat_gpt/get_request', {
                params: {
                    request_id: request_id
                },
            })
                .then(function (response)
                {
                    if (response.data != '')
                    {
                        document.getElementById('get_request_div').innerHTML = response.data;
                        setTimeout(function ()
                        {
                            scope.scroll_div();
                        }, 500);
                    }
                })
                .catch(function (error)
                {

                });
        },

        submit_request(ele = null, is_image = null)
        {
            let scope = this;
            let button_html = null;
            let request_id = scope.request_id;
            if (ele)
            {
                button_html = ele.innerHTML;
                show_loading_button(ele, 'Sending Request... ');
                remove_form_errors();
            }

            let form = document.getElementById('request_form');
            let formData = new FormData(form);

            if (is_image)
            {
                formData.set('request_type', 'images');
                scope.type_selected = 'images';
            }

            if (
                (scope.type_selected != 'images' && is_image) ||
                (scope.type_selected == 'images' && !is_image)
            )
            {
                request_id = new Date().getTime();
                formData.set('request_id', request_id);
                scope.request_id = request_id;
            }

            axios.post('/chat_gpt/submit_request', formData)
                .then(function (response)
                {
                    if (ele)
                    {
                        ele.innerHTML = button_html;
                    }
                    if (response.data.error)
                    {
                        if (response.data.type == 'general')
                        {
                            alert('Error: ' + response.data.error);
                        }
                        if (response.data.type == 'incorrect_type')
                        {
                            scope.confirm_image_search = true;
                        }
                        return false;
                    }
                    scope.get_requests();
                    scope.get_request(request_id);
                    scope.confirm_image_search = false;
                    setTimeout(function ()
                    {
                        scope.scroll_div();
                    }, 500);
                })
                .catch(function (error)
                {
                    if (ele)
                    {
                        display_errors(error, ele, button_html);
                    }
                });
        },

        delete_request(ele, request_id)
        {
            if (confirm('Are you sure you want to delete this'))
            {
                axios.get('/chat_gpt/delete_request', {
                    params: {
                        request_id: request_id
                    },
                })
                    .then(function (response)
                    {
                        ele.closest('.request-div').remove();
                        notify('success', 'Request deleted successfully');
                    })
                    .catch(function (error)
                    {

                    });
            }
        },

        new_request()
        {
            this.request_id = new Date().getTime();
            document.getElementById('get_request_div').innerHTML = '';
            document.querySelector('[name="request_text"]').value = '';
        },

        select_image(ele)
        {
            if (ele.value.match(/(create\san\simage|create\sa\spicture|make\san\simage|make\sa\spicture)/))
            {
                document.querySelector('.request-type-images').click();
            } else if (ele.value.match(/(write|describe)/))
            {
                document.querySelector('.request-type-chat').click();
            }
        },

        scroll_div()
        {
            const element = document.getElementById('latest_request');
            if (element)
            {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },



    }

}
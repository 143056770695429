
window.nav = function ()
{
    return {
        main_nav_open: window.innerWidth >= 1500,
        active_menu: '',
        active_sub_menu: '',
        has_notifications: false,
        show_notifications: false,
        notifications_count: 0,
        tasks_count: 0,
        init()
        {

            this.notifications();

            // document.addEventListener('DOMContentLoaded', function() {
            //     this.main_nav_open = window.innerWidth >= 1280;
            // });
        },

        main_nav_z_index()
        {
            let side_bar = document.getElementById('side_bar');

            if (this.main_nav_open == true)
            {
                side_bar.classList.remove('z-10');
                side_bar.classList.add('z-50');
            } else
            {
                side_bar.classList.add('z-10');
                side_bar.classList.remove('z-50');
            }
        },

        notifications()
        {
            let scope = this;
            scope.get_notifications();
            setInterval(() =>
            {
                if (scope.show_notifications === false)
                {
                    scope.get_notifications();
                }
            }, 5000);
        },

        get_notifications()
        {
            let scope = this;
            axios.get('/notifications')
                .then(function (response)
                {

                    document.getElementById('notifications_div').innerHTML = response.data;

                    let tasks_count = document.getElementById('tasks_count').innerText;
                    document.getElementById('menu_tasks_count').innerText = tasks_count;

                    let notifications_count = document.getElementById('notifications_count').innerText;
                    scope.notifications_count = notifications_count;

                    if (parseInt(notifications_count) > 0)
                    {
                        scope.has_notifications = true;
                    } else
                    {
                        scope.has_notifications = false;
                    }
                })
                .catch(function (error)
                {

                });
        },

        mark_as_read()
        {
            setTimeout(function ()
            {
                axios.get('/heritage_financial/crm/mark_as_read')
                    .then(function (response)
                    {
                    })
                    .catch(function (error)
                    {

                    });
            }, 3000);
        },

        complete_task(ele, task_id, completed)
        {
            axios.get('/heritage_financial/crm/complete_task', {
                params: {
                    task_id: task_id,
                    completed: completed
                },
            })
                .then(function (response)
                {
                    if (completed === 1)
                    {
                        let button_div = ele.closest('.button-div');
                        button_div.classList.add('hidden');
                        button_div.nextElementSibling.classList.remove('hidden');
                    } else
                    {
                        let completed_div = ele.closest('.completed-div');
                        completed_div.classList.add('hidden');
                        completed_div.previousElementSibling.classList.remove('hidden');
                    }
                })
                .catch(function (error)
                {

                });
        },


        delete_task(task_id)
        {
            let scope = this;
            if (confirm('Are you sure you want to delete this task?'))
            {
                axios.get('/heritage_financial/crm/delete_task', {
                    params: {
                        task_id: task_id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Task Successfully Deleted');

                        scope.get_tasks();
                        scope.get_activity('all');
                        scope.get_notifications();
                    })
                    .catch(function (error)
                    {

                    });
            }
        },
    }
}

window.navigation = function ()
{

    return {

        show_user_menu: false,
        show_mobile_menu: false,
        show_search_results_div: false,
        has_notifications: false,
        show_notifications: false,
        notifications_count: 0,
        tasks_count: 0,

        init()
        {
            this.mobile_links();
            this.notifications();
        },

        mobile_links()
        {
            let scope = this;
            let links = document.querySelectorAll('.parent');

            links.forEach(function (link)
            {
                let parent = link.closest('.parent-div');
                parent.addEventListener('click', function ()
                {
                    link.closest('.dropdown').querySelector('.dropdown-content').classList.toggle('hidden');
                });
            });
        },
        disableScroll()
        {
            document.body.classList.add('disable-scrolling');
        },
        enableScroll()
        {
            document.body.classList.remove('disable-scrolling');
        },
        notifications()
        {
            let scope = this;
            scope.get_notifications();
            setInterval(() =>
            {
                if (scope.show_notifications === false)
                {
                    scope.get_notifications();
                }
            }, 5000);
        },

        get_notifications()
        {
            let scope = this;
            axios.get('/notifications')
                .then(function (response)
                {

                    document.getElementById('notifications_div').innerHTML = response.data;

                    let tasks_count = document.getElementById('tasks_count').innerText;
                    document.getElementById('menu_tasks_count').innerText = tasks_count;

                    let notifications_count = document.getElementById('notifications_count').innerText;
                    scope.notifications_count = notifications_count;

                    if (parseInt(notifications_count) > 0)
                    {
                        scope.has_notifications = true;
                    } else
                    {
                        scope.has_notifications = false;
                    }
                })
                .catch(function (error)
                {

                });
        },

        mark_as_read()
        {
            setTimeout(function ()
            {
                axios.get('/heritage_financial/crm/mark_as_read')
                    .then(function (response)
                    {
                    })
                    .catch(function (error)
                    {

                    });
            }, 3000);
        },

        complete_task(ele, task_id, completed)
        {
            axios.get('/heritage_financial/crm/complete_task', {
                params: {
                    task_id: task_id,
                    completed: completed
                },
            })
                .then(function (response)
                {
                    if (completed === 1)
                    {
                        let button_div = ele.closest('.button-div');
                        button_div.classList.add('hidden');
                        button_div.nextElementSibling.classList.remove('hidden');
                    } else
                    {
                        let completed_div = ele.closest('.completed-div');
                        completed_div.classList.add('hidden');
                        completed_div.previousElementSibling.classList.remove('hidden');
                    }
                })
                .catch(function (error)
                {

                });
        },


        delete_task(task_id)
        {
            let scope = this;
            if (confirm('Are you sure you want to delete this task?'))
            {
                axios.get('/heritage_financial/crm/delete_task', {
                    params: {
                        task_id: task_id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Task Successfully Deleted');

                        scope.get_tasks();
                        scope.get_activity('all');
                        scope.get_notifications();
                    })
                    .catch(function (error)
                    {

                    });
            }
        },

    }

}


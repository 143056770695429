if(document.URL.match(/escrow/)) {

    window.escrow = function() {

        return {

        }

    }

}



window.upload_list = function ()
{

    return {

        list_type: 'title_deals',

        init()
        {
            
        },

        add_list(ele, input)
        {

            let scope = this;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Adding List ... ');
            remove_form_errors();

            let form = document.getElementById('add_list_form');
            let formData = new FormData(form);
            formData.append('type', scope.list_type);

            axios.post('/marketing/data/add_new_list', formData)
                .then(function (response)
                {
                    if(response.data.error) {
                        notify('error', response.data.error);
                    } else {
                        notify('success', 'List Successfully Added');
                    }
                    ele.innerHTML = button_html;
                    form.reset();
                    remove_file_names(input);
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });

        }

    }

}
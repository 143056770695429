window.software_survey = function ()
{
    
    return {

        init()
        {
             
        },

    }

}
window.open_houses = function ()
{

    return {
        view_open_house_details: false,
        view_more_details: false,

        init()
        {
            this.get_open_houses('tp');
            this.get_open_houses('non_tp');


        },

        get_open_houses(type)
        {
            let scope = this;
            axios.get('/heritage_financial/open_houses/get_open_houses', {
                params: {
                    type: type
                },
            })
                .then(function (response)
                {
                    if (document.getElementById('open_houses_' + type))
                    {
                        document.getElementById('open_houses_' + type).innerHTML = response.data;
                    }
                })
                .catch(function (error)
                {

                });
        },

        view_open_house(id)
        {
            let scope = this;
            scope.view_more_details = false;
            ele_loading(document.getElementById('open_house_details_div'));

            scope.view_open_house_details = true;

            axios.get('/heritage_financial/open_houses/view_open_house', {
                params: {
                    id: id
                },
            })
                .then(function (response)
                {
                    document.getElementById('open_house_details_div').innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        change_location(type, div)
        {
            let scope = this;
            let parent = 'open_houses_tp';
            if (div == 'non_tp')
            {
                parent = 'open_houses_non_tp';
            }
            parent = document.getElementById(parent);
            let state = parent.querySelector('.options-state');
            let county = parent.querySelector('.options-county');
            let city = parent.querySelector('.options-city');

            axios.get('/heritage_financial/open_houses/change_location', {
                params: {
                    state: state.value,
                    county: county.value,
                    type: type
                },
            })
                .then(function (response)
                {
                    if (type == 'state')
                    {
                        county.innerHTML = '';
                        city.innerHTML = '';
                        county.classList.remove('label-added');
                        city.classList.remove('label-added');

                        let counties = Object.values(response.data.counties);

                        let county_option = document.createElement('option');
                        county_option.value = '';
                        county_option.text = 'All';
                        county.add(county_option);
                        county_option.selected = true;

                        counties.forEach(function (value)
                        {
                            if (value != '')
                            {
                                let option = document.createElement('option');
                                option.value = value;
                                option.text = value;
                                county.add(option);
                            }
                        });

                        let cities = Object.values(response.data.cities);

                        let city_option = document.createElement('option');
                        city_option.value = '';
                        city_option.text = 'All';
                        city.add(city_option);
                        city_option.selected = true;

                        cities.forEach(function (value)
                        {
                            if (value != '')
                            {
                                let option = document.createElement('option');
                                option.value = value;
                                option.text = value;
                                city.add(option);
                            }
                        });
                    } else if (type == 'county')
                    {
                        city.innerHTML = '';
                        city.classList.remove('label-added');

                        let cities = Object.values(response.data.cities);

                        let city_option = document.createElement('option');
                        city_option.value = '';
                        city_option.text = 'All';
                        city.add(city_option);
                        city_option.selected = true;

                        cities.forEach(function (value)
                        {
                            if (value != '')
                            {
                                let option = document.createElement('option');
                                option.value = value;
                                option.text = value;
                                city.add(option);
                            }
                        });
                    }
                })
                .catch(function (error)
                {

                });
        },


    }

}
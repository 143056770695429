if (document.URL.match(/queue_monitoring/))
{

    window.monitor = function ()
    {

        return {

            playing_interval: '',
            show_data_modal: false,

            init()
            {

                let scope = this;

                scope.get_stats();

                let table_options = {
                    'container': '#queue_data_div',
                    'data_url': '/admin/get_queue_data',
                    'sort_by': 'started_at',
                    'sort_dir': 'desc',
                    'length': '50',
                    'check_all': true,
                }
                data_tables(table_options).init();

            },

            play()
            {
                this.$refs.play_icon.classList.add('fa-spin');
                this.playing_interval = setInterval(() =>
                {
                    if (document.querySelectorAll('.check-all-input:checked').length == 0)
                    {
                        data_tables(JSON.parse(localStorage.getItem('table_options'))).init();
                        this.get_stats();
                    }
                }, 1000);
            },

            stop()
            {
                clearInterval(this.playing_interval);
                this.$refs.play_icon.classList.remove('fa-spin');
            },

            show_data(data)
            {
                this.show_data_modal = true;
                document.getElementById('data_view_div').innerHTML = '<pre>' + JSON.stringify(data, null, 2) + '</pre>';
            },


            get_stats()
            {
                let scope = this;
                axios.get('/admin/get_stats')
                    .then(function (response)
                    {
                        setTimeout(function ()
                        {
                            document.getElementById('status_div').innerHTML = response.data;
                        }, 500);
                    })
                    .catch(function (error)
                    {

                    });
            },

            delete_items(ele)
            {
                let scope = this;

                if (confirm('Are you sure you want to delete these records?'))
                {

                    let delete_all = document.querySelector('#table_delete_all').checked;
                    let checked = [];
                    if (delete_all == false)
                    {
                        document.querySelectorAll('.check-all-input').forEach(function (item)
                        {
                            if (item.checked)
                            {
                                checked.push(item.getAttribute('data-id'));
                            }
                        })
                    }
                    let button_html = ele.innerHTML;
                    show_loading_button(ele, 'Deleting ... ');

                    let formData = new FormData();
                    formData.append('checked', checked);
                    formData.append('delete_all', JSON.parse(delete_all));
                    formData.append('queue', document.querySelector('[name="queue"]').value);
                    formData.append('job', document.querySelector('[name="job"]').value);
                    formData.append('status', document.querySelector('[name="status"]').value);

                    axios.post('/admin/delete_items', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            data_tables().create_table();
                            notify('success', 'Item deleted successfully');
                            scope.get_stats();
                        })
                        .catch(function (error) { });

                }

            }


        }

    }

}
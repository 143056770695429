if (document.URL.match(/pay_checks/))
{
    window.pay_checks = function ()
    {
        return {

            init()
            {
                this.get_pay_checks();
                console.log('Pay Checks Loaded');
            },

            get_pay_checks()
            {
                let scope = this;
                axios.get('/apps/pay_checks/get_pay_checks')
                    .then(function (response)
                    {
                        scope.$refs.pay_checks_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            update_pay_check(pay_check_received, pay_check_date)
            {
                let scope = this;
                axios.post('/apps/pay_checks/update_pay_check', {
                    pay_check_received: pay_check_received,
                    pay_check_date: pay_check_date
                })
                    .then(function (response)
                    {
                        notify('success', 'Pay Check Updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

        }

    }
}
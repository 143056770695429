window.contacts = function ()
{

    return {

        show_item_modal: false,
        show_add_category: false,

        init()
        {

            let scope = this;
            scope.get_contacts();
        },

        get_contacts()
        {
            let scope = this;
            axios.get('/contacts/get_contacts')
                .then(function (response)
                {
                    scope.$refs.contacts_div.innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },

        show_item(contact_id)
        {

            let scope = this;

            scope.clear_form();

            scope.show_item_modal = true;

            if (contact_id)
            {

                // get and add data
                axios.get('/contacts/get_item_data', {
                    params: {
                        contact_id: contact_id
                    },
                })
                    .then(function (response)
                    {

                        scope.$refs.contact_id.value = contact_id;

                        let contact = response.data.contact;
                        for (const key in contact)
                        {
                            if (document.querySelector('[name="' + key + '"]'))
                            {
                                document.querySelector('[name="' + key + '"]').value = contact[key];
                            }
                        }

                    });

            }

        },

        clear_form()
        {
            this.$refs.contact_form.reset();
        },

        save_item(ele)
        {

            let scope = this;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = scope.$refs.contact_form;
            let formData = new FormData(form);

            axios.post('/contacts/save_item', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.show_item_modal = false;
                    notify('success', 'Contact successfully saved');
                    let scroll_to = document.getElementById('scroll_div').scrollTop;
                    scope.get_contacts();
                    setTimeout(function ()
                    {
                        document.getElementById('scroll_div').scrollTop = scroll_to;
                    }, 500);
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });


        },

        save_new_category(ele, category)
        {

            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let formData = new FormData();
            formData.append('category', category);

            axios.post('/contacts/save_new_category', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    let category_id = response.data.category_id;
                    let select = scope.$refs.category_id;
                    let option = new Option(category, category_id);
                    select.appendChild(option);
                    select.value = category_id;
                    scope.show_add_category = false;
                    scope.$refs.new_category.value = '';
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        delete_item(ele, contact_id)
        {

            if (confirm('Are you sure you want to delete this contact?'))
            {

                let formData = new FormData();
                formData.append('contact_id', contact_id);

                axios.post('/contacts/delete_contact', formData)
                    .then(function (response)
                    {
                        notify('success', 'Contact successfully deleted');
                        ele.closest('tr').classList.add('opacity-40');
                        ele.closest('tr').querySelectorAll('td').forEach(function (td)
                        {
                            td.classList.add('line-through');
                            let buttons = td.querySelectorAll('button');
                            if (buttons.length > 0)
                            {
                                buttons.forEach(function (button)
                                {
                                    button.remove();
                                });
                            }
                        });
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            }
        },


    }

}
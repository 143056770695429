if (document.URL.match(/reports/) && !document.URL.match(/company_accounts/))
{


    window.reports = function ()
    {

        let reports_active_tab = sessionStorage.getItem('reports_active_tab') ? parseInt(sessionStorage.getItem('reports_active_tab')) : 1;

        return {

            active_tab: reports_active_tab,
            active_mortgage_tab: 1,
            show_print_all_option: false,
            show_add_project: false,
            show_view_project: false,
            show_view_task: false,
            show_users: false,
            show_all_projects: false,
            active_company_id: null,
            show_upload_document: false,
            show_add_notes: false,
            show_view_discussion: false,
            show_discussions: false,

            init()
            {
                this.get_detailed_report(this.$refs.search_button);
                this.get_companies();
                this.discussion_text_editor();
            },

            get_detailed_report(ele)
            {

                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Searching ... ');

                let form = scope.$refs.detailed_report_form;
                let formData = new FormData(form);
                formData.set('report_type', 'data');

                axios.post('/reports/mortgage/get_detailed_report', formData)
                    .then(function (response)
                    {
                        scope.$refs.results_div_data.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });

                formData.set('report_type', 'details');
                axios.post('/reports/mortgage/get_detailed_report_details', formData)
                    .then(function (response)
                    {
                        scope.$refs.results_div_details.innerHTML = response.data;
                        ele.innerHTML = button_html;

                    })
                    .catch(function (error)
                    {
                    });

            },

            get_user_tasks()
            {
                let scope = this;
                axios.get('/reports/projects/get_user_tasks')
                    .then(function (response)
                    {
                        document.getElementById('user_tasks_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                    });
            },

            get_companies()
            {
                let scope = this;
                axios.get('/reports/projects/get_companies')
                    .then(function (response)
                    {
                        document.getElementById('companies_div').innerHTML = response.data;
                        scope.get_user_tasks();
                        setTimeout(scope.get_discussions, 100);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_discussions()
            {
                let scope = this;
                let divs = document.querySelectorAll('.discussions-div');

                divs.forEach(function (div)
                {
                    let company_id = div.getAttribute('data-company-id');
                    axios.get('/reports/projects/get_discussions', {
                        params: {
                            company_id: company_id
                        },
                    })
                        .then(function (response)
                        {
                            div.innerHTML = response.data;
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                });
            },

            discussion_text_editor()
            {
                let options = {
                    selector: '.text-editor',
                    min_height: 300,
                    width: '100%',
                    statusbar: false,
                    quickbars_selection_toolbar: 'bold italic link bullist numlist',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'help', 'autoresize', 'quickbars'
                    ],
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic | forecolor backcolor | image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    relative_urls: false,
                    document_base_url: location.hostname,
                }
                text_editor(options);
            },

            view_discussion(company_id, discussion_id = null)
            {
                if (enable_text_editor_in_development == true)
                {
                    tinymce.activeEditor.setContent('');
                }
                this.show_view_discussion = true;
                document.querySelector('[name="view_discussion_company_id"]').value = company_id;
                if(discussion_id)
                {
                    document.querySelector('[name="discussion_id"]').value = discussion_id;
                    this.get_discussion(discussion_id);
                }
            },

            get_discussion(discussion_id)
            {
                let scope = this;
                axios.get('/reports/projects/get_discussion', {
                    params: {
                        discussion_id: discussion_id
                    },
                })
                    .then(function (response)
                    {
                        if (enable_text_editor_in_development == true)
                        {
                            tinymce.activeEditor.setContent(response.data.note);
                        }
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_discussion(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();
                
                let formData = new FormData();
                let discussion_id = document.querySelector('[name="discussion_id"]').value;
                let company_id = document.querySelector('[name="view_discussion_company_id"]').value;
                let note = tinymce.activeEditor.getContent();
                formData.append('discussion_id', discussion_id);
                formData.append('company_id', company_id);
                formData.append('note', note);
                
                axios.post('/reports/projects/save_discussion', formData)
                .then(function (response) {
                    ele.innerHTML = button_html;
                    notify('success', 'Discussion saved successfully');
                    scope.show_view_discussion = false;
                    scope.get_discussions();
                    if (discussion_id != '')
                    {
                        scope.view_discussions(company_id);
                    }
                
                })
                .catch(function (error) {
                    display_errors(error, ele, button_html);
                });

            },

            delete_discussion(company_id, discussion_id, active)
            {
                let action = active == 0 ? 'archive' : 're-activate';
                if (confirm('Are you sure you want to '+action+' this discussion?'))
                {
                    let scope = this;
                    axios.post('/reports/projects/delete_discussion', {
                        discussion_id: discussion_id,
                        active: active
                    })

                        .then(function (response)
                        {
                            notify('success', 'Discussion deleted successfully');
                            scope.view_discussions(company_id);
                            scope.get_discussions();
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            view_discussions(company_id)
            {
                let scope = this;
                axios.get('/reports/projects/view_discussions', {
                    params: {
                        company_id: company_id
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('discussions_div').innerHTML = response.data;
                        scope.show_discussions = true;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });  
            },

            add_project(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Adding ... ');
                remove_form_errors();

                let form = document.getElementById('add_project_form');
                let formData = new FormData(form);

                axios.post('/reports/projects/add_project', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Project added successfully');
                        scope.show_add_project = false;
                        scope.view_project(response.data.project_id);
                        scope.get_companies();
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            view_projects(company_id)
            {
                let scope = this;
                scope.active_company_id = company_id;
                axios.get('/reports/projects/view_projects', {
                    params: {
                        company_id: company_id
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('view_projects_div').innerHTML = response.data;
                        scope.show_all_projects = true;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            view_project(project_id)
            {
                let scope = this;
                axios.get('/reports/projects/view_project', {
                    params: {
                        project_id: project_id
                    },
                })
                    .then(function (response)
                    {
                        scope.show_view_project = true;
                        document.getElementById('view_project_div').innerHTML = response.data;

                        scope.get_tasks(project_id);
                        scope.get_notes(project_id);
                        scope.get_documents(project_id);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_tasks(project_id)
            {
                axios.get('/reports/projects/get_tasks', {
                    params: {
                        project_id: project_id
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('tasks_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_project(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let form = document.getElementById('edit_project_form');
                let formData = new FormData(form);

                let project_id = formData.get('project_id');

                axios.post('/reports/projects/save_project', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Project saved successfully');
                        scope.view_project(project_id);
                        scope.get_companies();
                        if (scope.show_all_projects == true)
                        {
                            scope.view_projects(scope.active_company_id);
                        }

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_project()
            {
                if (confirm('Are you sure you want to delete this project?'))
                {
                    let scope = this;
                    let project_id = document.querySelector('[name="project_id"]').value;
                    axios.post('/reports/projects/delete_project', {
                        project_id: project_id
                    })
                        .then(function (response)
                        {
                            notify('success', 'Project deleted successfully');
                            scope.show_view_project = false;
                            scope.get_companies();
                            if (scope.show_all_projects == true)
                            {
                                scope.view_projects(scope.active_company_id);
                            }
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            upload_documents(ele)
            {
                let scope = this;
                let project_id = document.querySelector('[name="project_id"]').value;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Uploading ... ');
                remove_form_errors();

                let form = document.getElementById('upload_documents_form');
                let formData = new FormData(form);
                formData.append('project_id', project_id);

                axios.post('/reports/projects/upload_documents', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        form.reset();
                        scope.show_upload_document = false;
                        notify('success', 'Documents uploaded successfully');
                        scope.get_documents(project_id);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            get_documents(project_id)
            {
                axios.get('/reports/projects/get_documents', {
                    params: {
                        project_id: project_id
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('documents_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            delete_document(document_id)
            {

                if (confirm('Are you sure you want to delete this document?'))
                {
                    let scope = this;
                    axios.post('/reports/projects/delete_document', { document_id: document_id })
                        .then(function (response)
                        {
                            notify('success', 'Document deleted successfully');
                            scope.get_documents(document.querySelector('[name="project_id"]').value);
                        })
                        .catch(function (error)
                        {

                        });
                }
            },

            get_notes(project_id)
            {
                axios.get('/reports/projects/get_notes', {
                    params: {
                        project_id: project_id
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('notes_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_notes(ele)
            {
                let scope = this;
                let project_id = document.querySelector('[name="project_id"]').value;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Note ... ');
                remove_form_errors();

                let form = document.getElementById('add_notes_form');
                let formData = new FormData(form);
                formData.append('project_id', project_id);

                axios.post('/reports/projects/save_notes', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Notes saved successfully');
                        form.reset();
                        scope.show_add_notes = false;
                        scope.view_project(project_id);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_note(note_id)
            {
                if (confirm('Are you sure you want to delete this note?'))
                {
                    let scope = this;
                    axios.post('/reports/projects/delete_note', {
                        note_id: note_id
                    })
                        .then(function (response)
                        {
                            notify('success', 'Note deleted successfully');
                            scope.get_notes(document.querySelector('[name="project_id"]').value);
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            set_status(project_id, status_id)
            {
                let scope = this;
                axios.post('/reports/projects/set_status', {
                    project_id: project_id,
                    status_id: status_id
                })
                    .then(function (response)
                    {
                        notify('success', 'Status updated successfully');

                        // if closed or inactive
                        if (status_id === 3 || status_id == 1)
                        {
                            document.querySelector('[name="end_date"]').value = new Date().toLocaleDateString('en-CA');
                            scope.save_dates(null);
                        } else
                        {
                            document.querySelector('[name="end_date"]').value = '';
                            scope.save_dates(null);
                            // scope.view_project(project_id);
                            // scope.get_companies();
                        }
                        if (scope.show_all_projects == true)
                        {
                            scope.view_projects(scope.active_company_id);
                        }
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_dates(ele)
            {
                let scope = this;
                let button_html = null;
                if (ele)
                {
                    button_html = ele.innerHTML;
                    show_loading_button(ele, 'Saving ... ');
                }

                let form = document.getElementById('edit_dates_form');
                let formData = new FormData(form);
                let project_id = formData.get('project_id');

                axios.post('/reports/projects/save_dates', formData)
                    .then(function (response)
                    {
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                        notify('success', 'Dates saved successfully');
                        scope.view_project(project_id);
                        scope.get_companies();
                        if (scope.show_all_projects == true)
                        {
                            scope.view_projects(scope.active_company_id);
                        }
                    })
                    .catch(function (error)
                    {
                    });
            },

            view_task(task_id = null)
            {
                let scope = this;
                axios.get('/reports/projects/view_task', {
                    params: {
                        task_id: task_id
                    },
                })
                    .then(function (response)
                    {
                        scope.show_view_task = true;
                        document.getElementById('view_task_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });

            },

            save_task(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let form = document.querySelector('#view_task_form');
                let formData = new FormData(form);
                let project_id = document.querySelector('[name="project_id"]').value;
                formData.append('project_id', project_id);

                axios.post('/reports/projects/save_task', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_companies();
                        scope.get_tasks(project_id);
                        notify('success', 'Task saved successfully');
                        scope.show_view_task = false;

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_task(task_id)
            {
                if (confirm('Are you sure you want to delete this task?'))
                {
                    let scope = this;
                    axios.post('/reports/projects/delete_task', {
                        task_id: task_id
                    })
                        .then(function (response)
                        {
                            notify('success', 'Task deleted successfully');
                            scope.get_tasks(document.querySelector('[name="project_id"]').value);
                            scope.get_companies();
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

            mark_complete(task_id)
            {
                let scope = this;
                axios.post('/reports/projects/mark_complete', {
                    task_id: task_id
                })
                    .then(function (response)
                    {
                        notify('success', 'Task marked as complete');
                        scope.get_tasks(document.querySelector('[name="project_id"]').value);
                        scope.get_companies();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            mark_incomplete(task_id)
            {
                let scope = this;
                axios.post('/reports/projects/mark_incomplete', {
                    task_id: task_id
                })
                    .then(function (response)
                    {
                        notify('success', 'Task marked as incomplete');
                        scope.get_tasks(document.querySelector('[name="project_id"]').value);
                        scope.get_companies();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            get_users()
            {
                let scope = this;
                axios.get('/reports/projects/get_users')
                    .then(function (response)
                    {
                        document.getElementById('users_div').innerHTML = response.data;
                        scope.show_users = true;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_users(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Users ... ');

                let form = scope.$refs.users_form;
                let formData = new FormData(form);

                axios.post('/reports/projects/save_users', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Users Successfully Updated');
                        scope.show_users = false;
                    })
                    .catch(function (error)
                    {
                    });
            },

            clear_add_project_form()
            {
                document.getElementById('add_project_form').reset();
            },


        }

    }

    window.chart_closed_loans_by_month = function ()
    {

        axios.get('/reports/mortgage/chart_closed_loans_by_month')
            .then(function (response)
            {
                let loans = response.data.loans_data;

                let month_names = response.data.month_names;
                const chart = Highcharts.chart('closed_loans_by_month', {
                    chart: {
                        type: 'line',
                        height: 400
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        categories: month_names
                    },
                    yAxis: {
                        title: {
                            text: 'Loans'
                        }
                    },
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'top',
                        reversed: true
                    },
                    plotOptions: {
                        series: {
                            allowPointSelect: true
                        }
                    },
                    series: loans,
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                });
            })

            .catch(function (error)
            {

            });

    }

    window.reports_surveys = function (deal_id = null)
    {
        return {

            show_deal_modal: false,
            active_div: 'borrowers',
            deal_id: deal_id,

            init()
            {
                if (deal_id)
                {
                    this.view_deal(deal_id);
                }
            },

            view_deal(deal_id)
            {
                let scope = this;

                axios.get('/reports/title/view_deal', {
                    params: {
                        deal_id: deal_id
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.deal_div.innerHTML = response.data;
                        scope.show_deal_modal = true;
                    })
                    .catch(function (error)
                    {
                    });

            },
        }

    }



}

if (document.URL.match(/tools/))
{

    window.tools = function ()
    {

        return {

            init()
            {

            },

            create_classes(ele, type)
            {

                let scope = this;

                let style = scope.$refs.style.value;
                let level = scope.$refs.level.value;
                let single = scope.$refs.single.value;
                let write_to_file = document.querySelector('[name="write_to_file"]:checked').value;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                let formData = new FormData();
                formData.append('type', type);
                formData.append('style', style);
                formData.append('level', level);
                formData.append('single', single);
                formData.append('write_to_file', write_to_file);

                axios.post('/tools/create_classes', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.$refs.classes_div.innerHTML = response.data;

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });

            },

        }

    }

    window.import_from_production = function ()
    {

        return {

            with_data: true,
            show_create_table: false,

            init()
            {
                this.set_connection('mysql_production');
            },

            set_connection(connection)
            {
                let scope = this;
                axios.get('/tools/set_connection', {
                    params: {
                        connection: connection
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.tables.innerHTML = '';
                        response.data.tables.forEach(function (table)
                        {
                            let option = document.createElement('option');
                            option.value = table;
                            option.text = table;
                            scope.$refs.tables.add(option);
                        });
                    })
                    .catch(function (error)
                    {

                    });
            },

            show_selected()
            {
                let scope = this;

                scope.$refs.selected_tables.innerHTML = '';

                for (var option of scope.$refs.tables.options)
                {
                    if (option.selected)
                    {
                        scope.$refs.selected_tables.innerHTML += option.value + '<br>';
                    }
                }
            },

            import_tables(ele)
            {

                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Importing ... ');
                remove_form_errors();

                let connection = document.getElementById('connection').value;

                let form = scope.$refs.import_form;
                let formData = new FormData(form);
                formData.append('with_data', scope.with_data);
                formData.append('connection', connection);

                axios.post('/tools/import_tables', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Tables successfully imported');
                        scope.$refs.selected_tables.innerHTML = '';

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });

            },

            get_create_table(table)
            {
                let scope = this;
                axios.get('/tools/get_create_table', {
                    params: {
                        table: table
                    },
                })
                    .then(function (response)
                    {
                        scope.show_create_table = true;
                        scope.$refs.create_table_div.innerText = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

        }

    }

    window.add_agent_ids = function ()
    {

        return {

            init()
            {

            },

            update_agent_id(ele, deal_id, agent_id, agent_type)
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let formData = new FormData();
                formData.append('deal_id', deal_id);
                formData.append('agent_id', agent_id);
                formData.append('agent_type', agent_type);

                axios.post('/tools/update_agent_id', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Agent ID successfully updated');
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

        }

    }

}
if (document.URL.match(/acknowledgement/))
{
    window.acknowledgement = function ()
    {

        return {

            show_add_acknowledgement_modal: false,
            show_preview_acknowledgement_modal: false,
            show_email_acknowledgement_modal: false,
            show_acknowledgement_modal: false,
            show_recipients_modal: false,
            document_type: 'html',
            recipient: '',
            cloning: false,

            init()
            {
                let scope = this;
                scope.get_acknowledgements();
                scope.add_text_editor();
                scope.get_agents();
            },

            get_acknowledgements(page = 1)
            {
                let scope = this;
                axios.get('/acknowledgements/get_acknowledgements?page=' + page)
                    .then(function (response)
                    {
                        document.querySelector('#acknowledgements_div').innerHTML = response.data;
                        scope.pagination();
                    })
                    .catch(function (error)
                    {

                    });
            },

            get_agents()
            {
                let scope = this;
                let include_referral = false;
                if (document.querySelector('#include_referral'))
                {
                    include_referral = document.querySelector('#include_referral').checked;
                }
                axios.get('/acknowledgements/get_agents', {
                    params: {
                        include_referral: include_referral
                    },
                })
                    .then(function (response)
                    {
                        document.querySelector('#agents_div').innerHTML = response.data;
                        scope.reset_recipients('agents')
                    })
                    .catch(function (error)
                    {

                    });
            },

            exclude_recipient(ele, parent_div, id, name = null)
            {
                let excluded = document.querySelector('#excluded_div');
                if (ele.checked == false)
                {
                    let div = document.createElement('div');
                    div.classList.add('p-1', 'border-b', 'mb-1', 'excluded-div');
                    div.setAttribute('data-id', id);
                    div.id = 'excluded_' + id;
                    div.innerHTML = '<div @click="include_recipient($el, \'' + parent_div + '\', ' + id + ')" class="cursor-pointer"><a href="javascript:void(0)"><i class="fa-light fa-xmark text-red-500 fa-lg mr-2"></i></a> ' + name + '</div>';
                    excluded.appendChild(div);

                } else
                {
                    if (excluded.querySelector('#excluded_' + id))
                    {
                        excluded.querySelector('#excluded_' + id).remove();
                    }
                }
            },

            include_recipient(ele, parent_div, id)
            {
                document.querySelector('.' + parent_div).querySelector('[data-id="' + id + '"]').checked = true;
                ele.closest('.excluded-div').remove();
            },

            save(ele, preview = false)
            {
                let scope = this;
                let button_html = ele.innerHTML;


                if (preview == true)
                {
                    show_loading_button(ele, 'Generating ... ');
                } else
                {
                    show_loading_button(ele, 'Saving ... ');
                    ele.disabled = true;
                }
                remove_form_errors();

                let send_to_ids = [];
                document.querySelectorAll('.recipient-checkbox:checked').forEach(function (checkbox)
                {
                    send_to_ids.push({ id: checkbox.value });
                });
                if (send_to_ids.length == 0)
                {
                    alert('You must select at least one recipient');
                    ele.innerHTML = button_html;
                    ele.disabled = false;
                    return false;
                }

                let form = document.querySelector('#add_acknowledgement_form');
                let formData = new FormData(form);
                let title = document.querySelector('#title').value;
                let document_type = document.querySelector('#document_type').value;
                let document_html = tinyMCE.get('document_html').getContent();
                document.querySelector('#document_html').value = document_html;
                let description = tinyMCE.get('description').getContent();
                let subject = document.querySelector('#subject').value;
                let message = tinyMCE.get('message').getContent();
                formData.set('title', title);
                formData.set('preview', preview);
                formData.set('document_type', document_type);
                formData.set('document_html', document_html);
                formData.set('description', description);
                formData.set('subject', subject);
                formData.set('message', message);
                formData.set('send_to_ids', JSON.stringify(send_to_ids));

                axios.post('/acknowledgements/save', formData)
                    .then(function (response)
                    {
                        if (preview == true)
                        {
                            if (response.data.status == 'success')
                            {
                                scope.show_preview_acknowledgement_modal = true;
                                scope.$refs.preview_description.innerHTML = description;
                                scope.$refs.preview_description.querySelectorAll('p').forEach(function (par)
                                {
                                    par.classList.add('mb-2');
                                });
                                if (response.data.file_location)
                                {
                                    let file_location = response.data.file_location + '#toolbar=0';
                                    scope.$refs.preview_pdf.setAttribute('data', file_location);
                                } else
                                {
                                    scope.$refs.preview_html.innerHTML = document_html;
                                }
                            }
                        } else
                        {
                            scope.clear_add_acknowledgement();
                            notify('success', 'Acknowledgement Successfully Created and Sent!');
                            scope.get_acknowledgements();
                        }
                        ele.innerHTML = button_html;
                        ele.disabled = false;

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html, 'large');
                        ele.innerHTML = button_html;
                        ele.disabled = false;
                    });


            },

            clone_acknowledgement(id)
            {
                let scope = this;
                scope.cloning = true;
                axios.get('/acknowledgements/clone_acknowledgement', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        let acknowledgement = response.data.acknowledgement;
                        // let recipients = acknowledgement.recipients;

                        scope.show_add_acknowledgement_modal = true;

                        scope.document_type = acknowledgement.document_type;

                        document.querySelector('#title').value = acknowledgement.title;
                        if (enable_text_editor_in_development == true)
                        {
                            tinyMCE.get('description').setContent(acknowledgement.description);
                            if (scope.document_type == 'html')
                            {
                                tinyMCE.get('document_html').setContent(acknowledgement.document_html);
                            }
                        }

                        document.querySelector('#subject').value = acknowledgement.email_subject;
                        if (enable_text_editor_in_development == true)
                        {
                            setTimeout(function ()
                            {
                                tinyMCE.get('message').setContent(acknowledgement.email_message);
                            }, 500);
                        }

                        // Object.entries(recipients).forEach(([key, value]) =>
                        // {
                        //     document.querySelector('.' + scope.recipient).querySelector('[data-user-id="' + value.user_id + '"]').checked = true;
                        // });
                        // document.querySelector('.' + scope.recipient).querySelectorAll('.recipient-checkbox:not(checked)').forEach(function (checkbox)
                        // {
                        //     checkbox.click();
                        //     checkbox.click();
                        // });
                    })
                    .catch(function (error)
                    {

                    });
            },

            delete_acknowledgement(id)
            {
                if (confirm('Are you sure you want to delete this acknowledgement?'))
                {
                    let scope = this;
                    let formData = new FormData();
                    formData.append('id', id);

                    axios.post('/acknowledgements/delete_acknowledgement', formData)
                        .then(function (response)
                        {
                            scope.get_acknowledgements();
                        })
                        .catch(function (error)
                        {
                            display_errors(error, ele, button_html);
                        });
                }
            },

            show_email()
            {
                let scope = this;
                scope.show_email_acknowledgement_modal = true;

                let src = 'https://tpuserportal.com/images/logo/taylor_properties.png';
                let signature = `
                Taylor Properties<br>
                175 Admiral Cochrane Dr.<br>
                Suite 112<br>
                Annapolis, MD 21401
                `;
                if (scope.recipient == 'loan_officers')
                {
                    src = 'https://tpuserportal.com/images/logo/heritage_financial.png';
                    signature = `
                    Heritage Financial, Inc.<br>
                    175 Admiral Cochrane Dr.<br>
                    Suite 112<br>
                    Annapolis, MD 21401
                    `;
                } else if (scope.recipient == 'title')
                {
                    src = 'https://tpuserportal.com/images/logo/heritage_title.png';
                    signature = `
                    Heritage Title<br>
                    175 Admiral Cochrane Dr.<br>
                    Suite 111<br>
                    Annapolis, MD 21401
                    `;
                }
                let message = `
                <div style="width: 600px; padding: 5px; font-size: 14px; color: #5d5b5b">
                    <table width="100%">
                        <tr>
                            <td align="center" style="padding: 10px; border-bottom: 1px solid #ccc;"><img src="`+ src + `" style="width: 125px;"></td>
                        </tr>
                    </table>
                    <div style="padding-top: 10px">
                    Hello ##first_name##,
                    <br><br>
                    ##company## has added/updated an internal policy which needs your acknowledgement.
                    <br><br>
                    Please click the link below to view and sign the acknowledgement.
                    <br><br>
                    <a href="##site_url##/agents/acknowledgements/sign_acknowledgement/##recipient_id##/##recipient_email##" target="_blank">##site_url##/agents/acknowledgements/sign_acknowledgement/##recipient_id##/##recipient_email##</a>
                    </div>
                    <div style="padding-top: 30px">
                    Thank you,<br><br>
                    `+ signature + `
                    </div>
                </div>
                `;
                if (!scope.cloning)
                {
                    setTimeout(function ()
                    {
                        if (enable_text_editor_in_development == true)
                        {
                            tinyMCE.get('message').setContent(message);
                        }
                        document.querySelector('#subject').value = document.querySelector('#subject').getAttribute('data-default-value');
                    }, 500);
                }
            },


            view_acknowledgement(id)
            {
                let scope = this;

                scope.show_acknowledgement_modal = true;
                axios.get('/acknowledgements/view_acknowledgement', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        document.querySelector('#acknowledgement_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },


            view_recipients(id)
            {
                let scope = this;

                scope.show_recipients_modal = true;
                axios.get('/acknowledgements/view_recipients', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        document.querySelector('#recipients_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },

            search_recipients(ele)
            {
                let recipients = ele.closest('section').querySelectorAll('.recipient');
                let search = new RegExp(ele.value, 'i');
                recipients.forEach(function (recipient)
                {
                    if (recipient.getAttribute('data-recipient').match(search))
                    {
                        recipient.classList.remove('hidden');
                    } else
                    {
                        recipient.classList.add('hidden');
                    }
                });
            },

            resend_notice_email(ele, recipient_id)
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Sending ... ');
                let formData = new FormData();
                formData.append('recipient_id', recipient_id);

                axios.post('/acknowledgements/resend_notice_email', formData)
                    .then(function (response)
                    {
                        if (response.data.error)
                        {
                            notify('error', response.data.error);
                            ele.innerHTML = button_html;
                            return;
                        }
                        ele.innerHTML = 'Successfully Sent <i class="fa-light fa-check-circle text-white ml-2"></i>';
                        notify('success', 'Successfully Sent Notice Email');
                    })
                    .catch(function (error)
                    {
                    });
            },
            resend_completed(ele, recipient_id)
            {
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Sending ... ');

                let formData = new FormData();
                formData.append('recipient_id', recipient_id);

                axios.post('/acknowledgements/resend_completed', formData)
                    .then(function (response)
                    {
                        if (response.data.error)
                        {
                            notify('error', response.data.error);
                            ele.innerHTML = button_html;
                            return;
                        }
                        ele.innerHTML = button_html;
                        notify('success', 'Successfully Sent Acknowledgement');
                    })
                    .catch(function (error)
                    {
                    });
            },

            download_completed(ele, recipient_id)
            {
                axios.get('/acknowledgements/download_completed', {
                    params: {
                        recipient_id: recipient_id
                    },
                })
                    .then(function (response)
                    {
                        window.open(response.data.url);
                    })
                    .catch(function (error)
                    {

                    });
            },

            recipient_status(recipient_id, active)
            {

                axios.get('/acknowledgements/recipient_status', {
                    params: {
                        recipient_id: recipient_id,
                        active: active
                    },
                })
                    .then(function (response)
                    {
                        let action = active == 1 ? 'Reactivated' : 'Deactivated';
                        notify('success', 'Successfully ' + action + ' Recipient');
                    })
                    .catch(function (error)
                    {

                    });
            },

            reset_recipients(recipients_list = null)
            {
                document.querySelector('#excluded_div').innerHTML = '';
                document.querySelector('#recipients').querySelectorAll('.recipient-checkbox').forEach(function (checkbox)
                {
                    checkbox.checked = false;
                });
                if (recipients_list)
                {
                    document.querySelector('#recipients').querySelector('.' + recipients_list).querySelectorAll('.recipient-checkbox').forEach(function (checkbox)
                    {
                        checkbox.checked = true;
                    });
                } else
                {
                    this.recipient = '';
                }
            },

            update_resend_interval(ele)
            {
                let days = ele.value;
                let formData = new FormData();
                formData.append('days', days);

                axios.post('/acknowledgements/update_resend_interval', formData)
                    .then(function (response)
                    {
                        notify('success', 'Successfully Updated Resend Interval');
                    })
                    .catch(function (error)
                    {
                    });
            },

            set_defaults()
            {
                if (enable_text_editor_in_development == true)
                {
                    tinyMCE.get('description').setContent(document.querySelector('#description').getAttribute('data-default-value'));
                }
                document.querySelector('#description').value = document.querySelector('#description').getAttribute('data-default-value');
            },

            clear_add_acknowledgement()
            {
                this.cloning = false;
                this.show_add_acknowledgement_modal = false;
                this.show_preview_acknowledgement_modal = false;
                this.show_email_acknowledgement_modal = false;
                document.querySelector('#add_acknowledgement_form').reset();
                remove_file_names(document.querySelector('#file_upload'));
                this.reset_recipients();


            },

            add_text_editor()
            {
                let options = {
                    selector: '.text-editor',
                    height: '300',
                    statusbar: false,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'image', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'autoresize'
                    ],
                    toolbar: 'undo redo bold italic | forecolor backcolor | image | bullist numlist outdent indent | code |',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    relative_urls: false,
                    document_base_url: location.hostname,
                }
                text_editor(options);

                let options_advanced = {
                    selector: '.text-editor-advanced',
                    height: '300',
                    autoresize_min_height: 300,
                    autoresize_max_height: 600,
                    min_height: 300,
                    max_height: 600,
                    statusbar: false,
                    menubar: true,
                    plugins: 'image table code autoresize advlist lists',
                    toolbar: 'undo redo | table | bold italic underline | forecolor backcolor | numlist bullist | align outdent indent |  numlist | image | formatselect fontselect fontsizeselect | code |',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    relative_urls: false,
                    document_base_url: location.hostname,
                }
                text_editor(options_advanced);

            },

            pagination()
            {
                let scope = this;
                document.querySelector('.length-option').remove();
                document.querySelectorAll('.pagination-link').forEach(function (link)
                {

                    link.addEventListener('click', function (e)
                    {
                        e.preventDefault();
                        let url = link.href;
                        let href = new URL(url);
                        let params = new URLSearchParams(href.search);
                        let page = params.get('page');
                        scope.get_acknowledgements(page);
                    });
                });
            }

        }

    }

}

window.surveys = function ()
{

    return {

        show_survey_modal: false,

        init()
        {
            this.get_surveys();

        },

        get_surveys()
        {
            let scope = this;
            axios.get('/marketing/title/surveys/get_surveys')
                .then(function (response)
                {
                    scope.$refs.surveys_div.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },


        show_survey(id = null)
        {
            let scope = this;

            scope.show_survey_modal = true;
            scope.clear_survey_form();

            if (id)
            {
                axios.get('/marketing/title/surveys/get_survey', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        let survey = response.data.survey;
                        let questions = response.data.questions;
                        let questions_div = scope.$refs.questions_div;
                        let recipients_div = scope.$refs.recipients_div;
                        scope.$refs.title.value = survey.title;
                        scope.$refs.description.value = survey.description;
                        scope.$refs.survey_id.value = survey.id;

                        if (survey.published == 1)
                        {
                            scope.$refs.survey_form.querySelector('fieldset').disabled = true;
                        }

                        recipients_div.querySelector('input[value="' + survey.recipients + '"]').checked = true;

                        questions_div.innerHTML = '';

                        questions.forEach(function (question)
                        {
                            let template = scope.$refs.question_template;
                            let clone = template.content.cloneNode(true);
                            let question_div = clone.querySelector('.question');
                            question_div.querySelector('textarea').value = question.question;
                            question_div.querySelector('select').value = question.rating_type;
                            questions_div.appendChild(clone);
                        });
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }
        },

        save_survey(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = scope.$refs.survey_form;
            let formData = new FormData(form);

            axios.post('/marketing/title/surveys/save_survey', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Survey saved successfully');
                    scope.show_survey_modal = false;
                    scope.get_surveys();
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        add_question()
        {
            let template = this.$refs.question_template;
            let clone = template.content.cloneNode(true);
            let parent = this.$refs.questions_div;
            parent.appendChild(clone);
            let c = 1;
            this.update_question_indexes();
        },

        remove_question(ele)
        {
            let scope = this;
            let parent = ele.closest('.question');
            parent.remove();
            let c = 1;
            this.update_question_indexes();
        },

        update_question_indexes()
        {
            setTimeout(function ()
            {
                let c = 1;
                document.querySelector('.questions-div').querySelectorAll('.form-element-label:has(+ textarea)').forEach(function (label)
                {
                    label.innerText = 'Question ' + c;
                    c++;
                });
            }, 500);
        },

        clear_survey_form()
        {
            let form = this.$refs.survey_form;
            form.reset();
            this.$refs.survey_id.value = '';
            this.$refs.save_button.classList.remove('hidden');
            this.$refs.save_button.classList.add('block');
            let questions = this.$refs.questions_div;
            questions.innerHTML = '';
            this.add_question();
            this.$refs.survey_form.querySelector('fieldset').disabled = false;
            // form_elements(); 
        },

        delete_survey(id)
        {
            if (confirm('Are you sure you want to delete this survey?'))
            {
                let scope = this;
                axios.get('/marketing/title/surveys/delete_survey', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Survey deleted successfully');
                        scope.get_surveys();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }
        },

        publish_survey(id)
        {
            if (confirm('Are you sure you want to publish this survey?'))
            {
                let scope = this;
                axios.get('/marketing/title/surveys/publish_survey', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Survey published successfully');
                        scope.get_surveys();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }
        },

        unpublish_survey(id)
        {
            if (confirm('Are you sure you want to unpublish this survey?'))
            {
                let scope = this;
                axios.get('/marketing/title/surveys/unpublish_survey', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Survey unpublished successfully');
                        scope.get_surveys();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }  
        },

        clone_survey(id)
        {
            if (confirm('Are you sure you want to clone this survey?'))
            {
                let scope = this;
                axios.get('/marketing/title/surveys/clone_survey', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Survey cloned successfully');
                        scope.get_surveys();
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }  
        },

    }

}



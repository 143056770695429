window.public_documents = function ()
{

    return {

        show_upload_document: false,
        show_trashed: false,

        init()
        {
            this.get_documents();
        },

        get_documents()
        {
            let scope = this;
            axios.get('/documents/get_documents')
                .then(function (response)
                {
                    scope.$refs.documents_div.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });  
        },

        upload_document(ele)
        {

            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let form = scope.$refs.upload_form;
            let formData = new FormData(form);

            axios.post('/documents/upload_document', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.show_upload_document = false;
                    form.reset();
                    notify('success', 'Document uploaded successfully');
                    scope.get_documents();
                })
                .catch(function (error)
                {
                    ele.innerHTML = button_html;
                    display_errors(error, ele, button_html);
                });
        },

        delete_document(document_id, type)
        {
            if(!confirm('Are you sure you want to '+type+' this document?'))
            {
                return;
            }

            let scope = this;
            let data = {
                document_id: document_id,
                type: type
            };

            axios.post('/documents/delete_document', data)
                .then(function (response)
                {
                    scope.get_documents();
                    notify('success', 'Document action completed successfully');
                })
                .catch(function (error)
                {
                    notify('error', 'An error occurred. Please try again');
                });
        },


    }

}
if(document.URL.match(/commission_reports/)) {

    window.commission_reports = function() {

        return {

        }

    }

}

if (document.URL.match(/tracker/))
{

    window.tracker = function ()
    {
        return {

            view_task: false,
            run_task: false,
            active_category: '',
            show_delete: false,

            init()
            {
                this.get_tasks();
            },

            show_view_task(task_id = null, category_id = null, task_name = null, task_description = null, reminder_date, reminder_time, reminder_interval, reminder_interval_type)
            {
                let scope = this;
                scope.view_task = true;
                scope.$refs.task_form.reset();
                scope.$refs.task_id.value = '';

                if (task_id)
                {
                    scope.$refs.task_id.value = task_id;
                    scope.$refs.category_id.value = category_id;
                    scope.$refs.task_name.value = task_name;
                    scope.$refs.task_description.value = task_description;
                    scope.$refs.reminder_date.value = reminder_date;
                    scope.$refs.reminder_time.value = reminder_time;
                    scope.$refs.reminder_interval.value = reminder_interval;
                    scope.$refs.reminder_interval_type.value = reminder_interval_type;
                    scope.$refs.delete_task_button.setAttribute('data-id', task_id);
                    scope.show_delete = true;
                }
            },

            get_tasks()
            {
                let scope = this;
                axios.get('/apps/tracker/get_tasks')
                    .then(function (response)
                    {
                        scope.$refs.tasks_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_task(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let form = scope.$refs.task_form;
                let formData = new FormData(form);

                axios.post('/apps/tracker/save_task', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Task Successfully Added');
                        scope.view_task = false;
                        scope.get_tasks();
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            show_run_task(task_id, task_name)
            {
                let scope = this;
                scope.run_task = true;
                scope.$refs.run_task_id.value = task_id;
                scope.$refs.run_task_name.innerText = task_name;
            },

            save_run_task(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                let form = scope.$refs.run_task_form;
                let formData = new FormData(form);

                axios.post('/apps/tracker/save_run_task', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.run_task = false;
                        notify('success', 'Task Successfully Run');
                        scope.get_tasks();
                    })
                    .catch(function (error)
                    {
                    });
            },

            delete_task(task_id)
            {
                let scope = this;
                if (confirm('Are you sure you want to delete this task?'))
                {
                    axios.get('/apps/tracker/delete_task', {
                        params: {
                            task_id: task_id
                        },
                    })
                        .then(function (response)
                        {
                            scope.view_task = false;
                            notify('Task Successfully Deleted');
                            scope.get_tasks();
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }
            },

        }

    }

    window.settings = function ()
    {
        return {

            add_category: false,

            init()
            {
                this.get_categories();
            },

            get_categories()
            {
                let scope = this;

                axios.get('/apps/tracker/settings/get_categories')
                    .then(function (response)
                    {
                        scope.$refs.categories_div.innerHTML = response.data;
                        scope.sortable(scope.$refs.settings_div);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            save_category(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Category ... ');

                let form = scope.$refs.add_category_form;
                let formData = new FormData(form);

                axios.post('/apps/tracker/settings/save_category', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Category Successfully Added');
                        scope.get_categories();
                    })
                    .catch(function (error)
                    {
                    });
            },

            save_edit_category(ele)
            {
                let scope = this;

                let form = scope.$refs.edit_category_form;
                let formData = new FormData(form);

                axios.post('/apps/tracker/settings/save_edit_category', formData)
                    .then(function (response)
                    {
                        notify('success', 'Category Successfully Updated');
                        scope.get_categories();
                    })
                    .catch(function (error)
                    {
                    });
            },

            delete_category(category_id)
            {
                let scope = this;
                if (confirm('Are you sure you want to delete this category?'))
                {
                    axios.get('/apps/tracker/settings/delete_category', { params: { category_id: category_id } })
                        .then(function (response)
                        {
                            notify('success', 'Category Successfully Deleted');
                            scope.get_categories();
                        })
                        .catch(function (error)
                        {
                        });
                }
            },

            sortable(container)
            {

                let scope = this;

                container.querySelectorAll('.settings-options').forEach(function (sortable_div)
                {

                    let sortable = Sortable.create(sortable_div, {
                        handle: ".setting-handle",  // Drag handle selector within list items
                        draggable: ".settings-item",  // Specifies which items inside the element should be draggable
                        chosenClass: "sortable-chosen",  // Class name for the chosen item
                        ghostClass: "sortable-ghost",  // Class name for the drop placeholder
                        dragClass: "sortable-drag",  // Class name for the dragging item

                        onEnd: function (evt)
                        {

                            let ele = evt.item;
                            let container = ele.closest('.settings-options');
                            scope.settings_update_order(container);

                        },

                    });

                });

            },

            settings_update_order(container)
            {

                let settings = [];
                container.querySelectorAll('.settings-item').forEach(function (setting, i)
                {
                    let data = {
                        id: setting.getAttribute('data-id'),
                        order: i
                    }
                    settings.push(data);
                });

                let formData = new FormData();
                formData.append('settings', JSON.stringify(settings));
                axios.post('/apps/tracker/settings/settings_update_order', formData)
                    .then(function (response)
                    {
                        notify('success', 'Reorder Successful');
                    })
                    .catch(function (error)
                    {
                    });

            }

        }

    }
}
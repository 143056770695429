

window.agent_database = function ()
{

    return {

        show_check_all_options: false,
        show_agent_stats: false,
        disable_get_agent_stats: false,
        last_assigned_id: '',
        disabled_loan_officers: [],

        init()
        {
            this.get_agents();
            this.get_agent_stats();

            setInterval(() =>
            {
                if (this.show_agent_stats == true)
                {
                    if (this.disable_get_agent_stats == false)
                    {
                        this.get_agent_stats();
                    }
                }
            }, 2000);
        },

        get_agents()
        {
            let scope = this;
            axios.get('/heritage_financial/crm/get_agents')
                .then(function (response)
                {
                    document.querySelector('#agents_div').innerHTML = response.data;
                })
                .catch(function (error)
                {

                });
        },
        show_options_div()
        {
            let checked = false;
            if (document.querySelectorAll('.agent-checkbox:checked').length > 0)
            {
                checked = true;
            }
            this.show_check_all_options = checked;
        },

        assign_all_checked(ele, loan_officer_id)
        {
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');

            let scope = this;
            let inputs = document.querySelectorAll('.agent-checkbox:checked');
            let agent_ids = [];
            inputs.forEach(function (input)
            {
                agent_ids.push(input.getAttribute('data-id'));
            });

            let formData = new FormData();
            formData.append('loan_officer_id', loan_officer_id);
            formData.append('agent_ids', agent_ids);

            axios.post('/heritage_financial/crm/assign_loan_officer', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_agents();
                    scope.show_check_all_options = false;
                    scope.reset_check_all();
                })
                .catch(function (error)
                {
                });
        },

        update_loan_officer(agent_ids, loan_officer_id)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('agent_ids', agent_ids);
            formData.append('loan_officer_id', loan_officer_id);

            axios.post('/heritage_financial/crm/assign_loan_officer', formData)
                .then(function (response)
                {
                    notify('success', 'Loan Officer Successfully Added');
                    scope.last_assigned_id = loan_officer_id;
                    scope.disable_get_agent_stats = false;
                    setTimeout(function ()
                    {
                        scope.get_agent_stats();
                        scope.disable_get_agent_stats = true;
                    }, 1000);
                })
                .catch(function (error)
                {
                });
        },

        undo_loan_officer_change(ele)
        {
            let scope = this;
            let value = ele.getAttribute('data-default-value');
            ele.value = value;
            ele.closest('tr').classList.remove('loan-officer-assigned');

        },

        assign_lo(id, loan_officer, lo_states, no_agent = null)
        {
            let scope = this;
            let tr = document.querySelectorAll('.loan-officer:not(.loan-officer-assigned)')[0];
            if (tr)
            {
                let select = tr.querySelector('.loan-officer-select');
                let agent_states = select.getAttribute('data-states').replace(/\s/g, '');
                agent_states = agent_states.replace(/Maryland/, 'MD').split(',');
                lo_states = lo_states.replace(/\s/g, '').split(',');
                let state_found = false;

                if (no_agent)
                {
                    scope.set_lo(tr, id, select);
                    scope.add_history(loan_officer);
                    state_found = true;
                } else
                {
                    lo_states.forEach(function (lo_state)
                    {
                        if (agent_states.includes(lo_state))
                        {
                            scope.set_lo(tr, id, select);

                            scope.add_history(loan_officer);
                            state_found = true;
                            return;
                        }
                    });
                }

                if (state_found == false)
                {
                    notify('error', 'No Loan Officer Found For This State');
                }

            }
        },

        disable_loan_officer(id)
        {
            this.disabled_loan_officers.push(id);
            this.get_agent_stats();
        },

        add_history(loan_officer)
        {
            let history_div = this.$refs.history_div;
            let lo_div = document.createElement('div');
            lo_div.classList.add('mr-2', 'whitespace-nowrap');
            lo_div.innerText = loan_officer + ' |';
            history_div.prepend(lo_div);
        },

        set_lo(tr, id, select)
        {
            select.setAttribute('value', id);
            select.value = id;
            tr.classList.add('loan-officer-assigned');
            trigger_change(select, 'change');
        },

        get_agent_stats()
        {
            let scope = this;
            axios.get('/heritage_financial/crm/get_agent_stats', { params: { disabled_loan_officers: scope.disabled_loan_officers } })
                .then(function (response)
                {
                    scope.$refs.agent_stats.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        assign_agents()
        {
            this.show_agent_stats = !this.show_agent_stats;
            let select = document.querySelector('.length-option select');
            select.value = 1000;
            trigger_change(select, 'change');
            setTimeout(function ()
            {
                document.querySelector('.options-container').classList.toggle('hidden');
                this.hide_assigned();
            }, 500);

        },

        hide_assigned()
        {
            if (this.show_agent_stats == true)
            {
                document.querySelectorAll('.loan-officer-assigned').forEach(function (ele)
                {
                    ele.classList.add('hidden');
                });
                document.querySelector('.table-div').classList.add('h-500-px', 'overflow-auto');
                document.querySelector('.table-div table').classList.add('relative', 'border-collapse');
                document.querySelector('.table-div table thead tr').querySelectorAll('th').forEach(function (th)
                {
                    th.classList.add('sticky', 'top-0', 'z-20');
                });
            } else
            {
                document.querySelectorAll('.loan-officer-assigned').forEach(function (ele)
                {
                    ele.classList.remove('hidden');
                });
                document.querySelector('.table-div').classList.remove('h-500-px', 'overflow-auto');
                document.querySelector('.table-div table').classList.remove('relative', 'border-collapse');
                document.querySelector('.table-div table thead tr').querySelectorAll('th').forEach(function (th)
                {
                    th.classList.remove('sticky', 'top-0', 'z-10');
                });
            }
        },

        clear_not_assigned()
        {
            let scope = this;
            axios.get('/heritage_financial/crm/clear_not_assigned')
                .then(function (response)
                {
                    scope.get_agents();
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        reset_check_all()
        {
            document.getElementById('check_all').checked = false;
        },

        position_popup(ele, popup)
        {
            let rect = ele.getBoundingClientRect();
            let ele_top = rect.top;
            let screen_height = window.innerHeight;

            if (ele_top > screen_height / 2)
            {
                popup.classList.remove('top-0');
                popup.classList.add('bottom-0');
            } else
            {
                popup.classList.remove('bottom-0');
                popup.classList.add('top-0');
            }

        },

    }

}

window.crm_tasks = function ()
{
    return {

        create_task: false,
        create_task_active: false,
        add_reminder: false,
        task_type: '',
        agent_id: null,
        email_agent: false,
        call_agent: false,
        call_outcome: '',

        init()
        {
            let scope = this;
            scope.text_editor_init();
            scope.get_tasks('open');
            scope.get_tasks('upcoming');
            scope.get_tasks('completed');
        },

        get_tasks(type)
        {
            axios.get('/heritage_financial/crm/tasks/get_tasks', { params: { type: type } })
                .then(function (response)
                {
                    document.querySelector('#tasks_div_' + type).innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        open_create_task()
        {
            let scope = this;

            scope.create_task = true;
            scope.clear_forms();

            scope.action = 'Add';

            setTimeout(function ()
            {
                document.querySelector('[name=task_title]').focus();
            }, 500);

        },

        save_task(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Task... ');
            remove_form_errors();

            let form = document.getElementById('create_task_form');
            let formData = new FormData(form);
            let task_notes = tinyMCE.get('task_notes').getContent();
            formData.set('task_notes', task_notes);
            if (scope.agent_id)
            {
                formData.append('agent_id', scope.agent_id);
            }

            axios.post('/heritage_financial/crm/save_task', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'New Task Successfully Saved');
                    scope.create_task = false;
                    scope.get_tasks('open');
                    scope.get_tasks('upcoming');

                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        run_task(ele, id, task_type)
        {
            let agent_name = ele.getAttribute('data-agent-name');
            let agent_id = ele.getAttribute('data-agent-id');
            let agent_email = ele.getAttribute('data-agent-email');
            let agent_phone = ele.getAttribute('data-agent-phone');
            if (task_type === 'email')
            {
                document.querySelector('#email_task_id').value = id;
                this.open_email_agent(false, agent_name, agent_id, agent_email);
            } else if (task_type === 'call')
            {
                document.querySelector('#call_task_id').value = id;
                this.call_agent = true;

                document.querySelectorAll('.agent-full-name').forEach(function (ele)
                {
                    ele.innerText = agent_name;
                });
                document.querySelectorAll('.agent-phone').forEach(function (ele)
                {
                    ele.innerText = agent_phone;
                });
                document.querySelectorAll('.agent-phone').forEach(function (ele)
                {
                    ele.setAttribute('href', agent_phone);
                });
                document.querySelectorAll('.agent-id').forEach(function (ele)
                {
                    ele.value = agent_id;
                });
            }
        },

        complete_task(ele, task_id, completed)
        {
            axios.get('/heritage_financial/crm/complete_task', {
                params: {
                    task_id: task_id,
                    completed: completed
                },
            })
                .then(function (response)
                {
                    if (completed === 1)
                    {
                        let button_div = ele.closest('.button-div');
                        button_div.classList.add('hidden');
                        button_div.nextElementSibling.classList.remove('hidden');
                    } else
                    {
                        let completed_div = ele.closest('.completed-div');
                        completed_div.classList.add('hidden');
                        completed_div.previousElementSibling.classList.remove('hidden');
                    }
                })
                .catch(function (error)
                {

                });
        },

        edit_task(id, reminder = false, on_init = false)
        {
            let scope = this;
            scope.action = 'Edit';
            if (on_init == false)
            {
                scope.clear_forms();
            }
            axios.get('/heritage_financial/crm/edit_task', {
                params: {
                    id: id
                },
            })
                .then(function (response)
                {
                    scope.create_task = true;
                    let task = response.data.task;
                    scope.task_type = task.task_type;
                    scope.$refs.task_id.value = task.id;
                    scope.$refs.task_title.value = task.title;
                    scope.$refs.task_agent_id.value = task.agent_id;
                    scope.$refs.task_date.value = task.task_date.substring(0, 10);
                    scope.$refs.task_time.value = task.task_date.substring(11, 16);
                    document.querySelector('.date-option.task').querySelector('.date-option-select').classList.add('hidden');
                    document.querySelector('.date-option.task').querySelector('.date-option-input').classList.remove('hidden');
                    scope.show_custom_date(document.querySelector('#task_date'), true);
                    if (task.notification)
                    {
                        scope.add_reminder = true;
                        scope.$refs.reminder_set.checked = true;
                        scope.$refs.reminder_date.value = task.notification.reminder_date.substring(0, 10);
                        scope.$refs.reminder_time.value = task.notification.reminder_date.substring(11, 16);
                        document.querySelector('.date-option.reminder').querySelector('.date-option-select').classList.add('hidden');
                        document.querySelector('.date-option.reminder').querySelector('.date-option-input').classList.remove('hidden');
                        scope.show_custom_date(document.querySelector('#reminder_date'), true);
                    }
                    if (reminder == true)
                    {
                        scope.$refs.reminder_label_icon.classList.add('fa-beat', 'fa-xl');
                        scope.$refs.reminder_set.checked = true;
                        scope.add_reminder = true;
                        scope.$refs.reminder_div.classList.add('p-2', 'rounded-lg', 'border-primary-50', 'border-2', 'bg-primary-50');
                    }
                    if (task.notes)
                    {
                        setTimeout(function ()
                        {
                            if (enable_text_editor_in_development == true)
                            {
                                tinyMCE.get('task_notes').setContent(task.notes);
                            }
                        }, 1000);
                    }
                })
                .catch(function (error)
                {

                });
        },

        delete_task(task_id)
        {
            let scope = this;
            if (confirm('Are you sure you want to delete this task?'))
            {
                axios.get('/heritage_financial/crm/delete_task', {
                    params: {
                        task_id: task_id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Task Successfully Deleted');
                        scope.get_tasks('open');
                        scope.get_tasks('upcoming');
                    })
                    .catch(function (error)
                    {

                    });
            }
        },

        open_email_agent(clear_forms = true, agent_name, agent_id, agent_email)
        {
            let scope = this;

            scope.email_agent = true;

            if (clear_forms == true)
            {
                scope.clear_forms();
            }

            document.querySelector('.agent-full-name').innerText = agent_name;

            document.querySelector('span.agent-email').innerText = agent_email;

            document.querySelector('.agent-id').value = agent_id;
            document.querySelector('input.agent-email').value = agent_email;

            setTimeout(function ()
            {
                let signature_template = document.getElementById('signature_template');
                if (enable_text_editor_in_development == true)
                {
                    tinyMCE.get('message').setContent('<br><br><br>' + signature_template.innerHTML);
                }
                document.getElementById('subject').focus();
            }, 500);



        },

        send_email_agent(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Sending Email ... ');
            remove_form_errors();

            let form = document.getElementById('email_agent_form');
            let formData = new FormData(form);
            let body = tinyMCE.get('message').getContent();
            formData.append('body', body);

            axios.post('/heritage_financial/crm/send_email_agent', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Email Successfully Sent');
                    scope.email_agent = false;
                    scope.get_tasks('open');
                    scope.get_tasks('upcoming');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },


        open_call_agent()
        {
            let scope = this;

            scope.call_agent = true;
            setTimeout(function ()
            {
                scope.clear_forms();
            }, 200);

        },



        save_call_agent(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Details... ');
            remove_form_errors();

            let form = document.getElementById('call_agent_form');
            let formData = new FormData(form);
            let call_notes = tinyMCE.get('call_notes').getContent();
            formData.append('call_notes', call_notes);

            axios.post('/heritage_financial/crm/save_call_agent', formData)
                .then(function (response)
                {
                    let date = response.data.date;
                    ele.innerHTML = button_html;
                    notify('success', 'Call Details Successfully Saved');
                    scope.call_agent = false;
                    scope.get_tasks('open');
                    scope.get_tasks('upcoming');

                    scope.$refs.last_contact_date.innerText = date;
                    scope.$refs.last_contact_name.innerText = document.getElementById('last_contact_name_value').value;
                    scope.$refs.last_contact_outcome.innerText = document.getElementById('last_contact_outcome_value').value;
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },



        clear_forms()
        {

            let form = document.getElementById('email_agent_form');
            if (form)
            {
                form.reset();
            }
            form = document.getElementById('call_agent_form');
            if (form)
            {
                form.reset();
            }
            form = document.getElementById('create_task_form');
            if (form)
            {
                form.reset();
            }

            if (enable_text_editor_in_development == true)
            {
                tinyMCE.get('message').setContent('');
            
                tinyMCE.get('call_notes').setContent('');
            }

            this.$refs.reminder_label_icon.classList.remove('fa-beat', 'fa-xl');
            this.$refs.reminder_div.classList.remove('p-2', 'rounded-lg', 'border-primary-50', 'border-2', 'bg-primary-50');

            this.create_task_active = false;
            remove_form_errors();
            document.querySelectorAll('.create-task').forEach(function (check)
            {
                check.checked = false;
            });

            remove_file_names(document.querySelector('[name="attachments[]"]'));


            document.querySelectorAll('.date-field-input').forEach(function (input)
            {
                input.setAttribute('name', '');
            });
            document.querySelectorAll('.date-field-select').forEach(function (input)
            {
                input.setAttribute('name', input.getAttribute('data-default-name'));
            });

            // TODO end changes

            document.querySelectorAll('.date-option-select').forEach(function (input)
            {
                input.classList.remove('hidden');
            });
            document.querySelectorAll('.date-option-input').forEach(function (input)
            {
                input.classList.add('hidden');
            });
            this.call_outcome = '';
            this.task_type = '';
            this.add_reminder = false;

            document.querySelectorAll('.date-option-select').forEach(function (select)
            {
                select.classList.remove('hidden');
            });
            document.querySelectorAll('.date-option-input').forEach(function (input)
            {
                input.classList.add('hidden');
            });

            document.querySelector('#call_task_id').value = '';
            document.querySelector('#email_task_id').value = '';

        },

        show_custom_date(ele, force = false)
        {
            if (ele.value == 'custom' || force == true)
            {
                let task = ele.closest('.date-option');
                let date_picker = task.querySelector('.date-option-input').querySelector('.date-picker');
                task.querySelector('.date-option-select').classList.add('hidden');
                task.querySelector('.date-option-input').classList.remove('hidden');
                let ele_name = ele.getAttribute('name');
                ele.setAttribute('name', ele_name + '_removed');
                date_picker.setAttribute('name', ele_name);
                date_picker.click();
            }
        },

        text_editor_init()
        {
            let options = {
                selector: '.text-editor-basic',
                height: 200,
                statusbar: false,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'image', 'autoresize'
                ],
                toolbar: 'bold italic forecolor backcolor bullist numlist outdent indent image',
                relative_urls: false,
                document_base_url: location.hostname,
            }
            text_editor(options);

            let options_advanced = {
                selector: '.text-editor',
                height: 300,
                autoresize_min_height: 300,
                autoresize_max_height: 600,
                min_height: 300,
                max_height: 600,
                statusbar: false,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'image', 'visualblocks', 'code', 'fullscreen', 'media', 'table', 'autoresize'
                ],
                toolbar: 'undo redo bold italic forecolor backcolor image bullist numlist outdent indent code',
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                relative_urls: false,
                document_base_url: location.hostname,
            }
            text_editor(options_advanced);
        },

    }
}

window.stats_settings = function ()
{

    return {

        enable_edit: false,
        total: 0,

        init()
        {
            this.total_all();
        },

        total_all()
        {
            let scope = this;
            let inputs = document.querySelectorAll('.sub-total');
            scope.total = 0;
            inputs.forEach(function (input)
            {
                scope.total += parseInt(input.innerText);
            });
        },

        save_settings(ele)
        {
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving Settings ... ');
            remove_form_errors();

            let form = document.getElementById('settings_form');
            let formData = new FormData(form);

            axios.post('/heritage_financial/crm/save_settings', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    notify('success', 'Settings Saved');
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

    }

}

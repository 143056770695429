window.coming_soon = function ()
{

    return {

        show_view_coming_soon_listing: false,
        show_image_preview: false,
        show_map: false,

        init()
        {
            this.get_coming_soon();
        },

        get_coming_soon()
        {
            let scope = this;
            axios.get('/agents/coming_soon/get_coming_soon/')
                .then(function (response)
                {
                    scope.$refs.coming_soon_div.innerHTML = response.data;
                });
        },

        get_coming_soon_details(listing_id)
        {
            let scope = this;
            scope.show_view_coming_soon_listing = true;
            scope.$refs.coming_soon_listing_div.innerHTML = `
                    <div class="flex justify-center items-center h-full">
                        <i class="fa-duotone fa-spinner fa-spin fa-6x text-sky-500"></i>
                    </div>
                `;
            axios.get('/agents/coming_soon/get_coming_soon_details/' + listing_id)
                .then(function (response)
                {
                    scope.$refs.coming_soon_listing_div.innerHTML = response.data;

                });
        },

        load_image_preview(photo_location, title = null, bright_image = null)
        {
            let scope = this;
            scope.$refs.image_title_div.innerHTML = '';
            if (title)
            {
                scope.$refs.image_title_div.innerHTML = title;
            }
            if (bright_image)
            {
                scope.$refs.image_preview_div.innerHTML = '<img src="' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
            } else
            {
                scope.$refs.image_preview_div.innerHTML = '<img src="/storage/' + photo_location + '" class="max-h-[700px] rounded shadow" alt="">';
            }
            scope.show_image_preview = true;
        },

        view_map(address)
        {
            let scope = this;
            let src = scope.$refs.map_iframe.dataset.src;
            scope.$refs.map_iframe.src = src.replace('%%address%%', address);
            scope.show_map = true;
        },
    }
}
window.transactions_data = function ()
{
    return {

        show_chart_transactions_closed_count_by_month: false,
        show_chart_transactions_closed_volume_by_month: false,
        show_chart_transactions_represent_by_year: false,
        
        init()
        {
            let scope = this;
            scope.transactions_closed_count_by_month();
            scope.transactions_closed_volume_by_month();

        },
        transactions_closed_count_by_month()
        {
            let scope = this;
            axios.get('/reports/real_estate/transactions_closed_count_by_month')
                .then(function (response)
                {
                    scope.$refs.transactions_closed_count_by_month.innerHTML = response.data;
                    scope.chart_transactions_closed_count_by_month();
                })
                .catch(function (error)
                {

                });
        },
        chart_transactions_closed_count_by_month()
        {
            let scope = this;
            axios.get('/reports/real_estate/chart_transactions_closed_count_by_month')
                .then(function (response)
                {
                    let transactions = response.data.transactions_data;
                    let years = response.data.years;
                    let month_names = response.data.month_names;

                    const chart = Highcharts.chart('chart_transactions_closed_count_by_month', {
                        chart: {
                            type: 'line'
                        },
                        title: {
                            text: 'Total Transactions',
                        },
                        xAxis: {
                            categories: month_names
                        },
                        yAxis: {
                            title: {
                                text: 'Transactions'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                        },
                        plotOptions: {
                            series: {
                                allowPointSelect: true
                            }
                        },
                        series: transactions,
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        }
                    });

                    scope.show_chart_transactions_closed_count_by_month = true;
                        scope.chart_transactions_represent_by_year();
                })

                .catch(function (error)
                {

                });

        },
        transactions_closed_volume_by_month()
        {
            let scope = this;
            axios.get('/reports/real_estate/transactions_closed_volume_by_month')
                .then(function (response)
                {
                    scope.$refs.transactions_closed_volume_by_month.innerHTML = response.data;
                    
                    setTimeout(function ()
                    {
                        scope.chart_transactions_closed_volume_by_month();
                        
                    }, 1600);
                })
                .catch(function (error)
                {

                });
        },
        chart_transactions_closed_volume_by_month()
        {
            let scope = this;
            axios.get('/reports/real_estate/chart_transactions_closed_volume_by_month')
                .then(function (response)
                {
                    let transactions = response.data.transactions_data;
                    let years = response.data.years;
                    let month_names = response.data.month_names;

                    const chart = Highcharts.chart('chart_transactions_closed_volume_by_month', {
                        chart: {
                            type: 'line'
                        },
                        title: {
                            text: '',
                        },
                        xAxis: {
                            categories: month_names
                        },
                        yAxis: {
                            title: {
                                text: 'Volume'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                        },
                        plotOptions: {
                            series: {
                                allowPointSelect: true
                            }
                        },
                        series: transactions,
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        }
                    });

                    scope.show_chart_transactions_closed_volume_by_month = true;
                })

                .catch(function (error)
                {

                });

        },
        chart_transactions_represent_by_year()
        {
            let scope = this;
            axios.get('/reports/real_estate/chart_transactions_represent_by_year')
                .then(function (response)
                {
                    let transactions = response.data.transactions_data;
                    let years = response.data.years;

                    const chart = Highcharts.chart('chart_transactions_represent_by_year', {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: 'Side of Transaction'
                        },
                        xAxis: {
                            categories: years,
                        },
                        yAxis: {
                            title: {
                                text: 'Transactions'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                        },
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        },
                        series: transactions
                    });
                    scope.show_chart_transactions_represent_by_year = true;
                })

                .catch(function (error)
                {

                });

        },

    }
}


window.applications = function ()
{

    return {

        init()
        {
            this.get_applications();
        },

        get_applications()
        {
            let scope = this;
            let url = '/heritage_financial/get_applications';
            axios.get(url)
                .then(response =>
                {
                    scope.$refs.applications_div.innerHTML = response.data;
                })
                .catch(error =>
                {
                    console.log(error);
                });
        },

        add_to_merge()
        {
            let started = document.querySelector('.started-checkbox:checked');
            let submitted = document.querySelector('.submitted-checkbox:checked');
            let merge_button = this.$refs.merge_button;

            if (started)
            {
                let borrower = started.getAttribute('data-borrower');
                this.$refs.started_application_div.innerHTML = borrower;
            } else
            {
                this.$refs.started_application_div.innerHTML = 'Select an application';
            }

            if (submitted)
            {
                let borrower = submitted.getAttribute('data-borrower');
                this.$refs.submitted_application_div.innerHTML = borrower;
            } else
            {
                this.$refs.submitted_application_div.innerHTML = 'Select an application';
            }

            if (started && submitted)
            {
                merge_button.disabled = false;
            } else
            {
                merge_button.disabled = true;
            }
        },

        merge_applications(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Merging ... ');

            let formData = new FormData();
            let started_id = document.querySelector('.started-checkbox:checked').value;
            let submitted_id = document.querySelector('.submitted-checkbox:checked').value;
            formData.append('started_id', started_id);
            formData.append('submitted_id', submitted_id);

            axios.post('/heritage_financial/merge_applications', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_applications();
                })
                .catch(function (error)
                {
                });
        },

        delete_application(id)
        {
            if (confirm('Are you sure you want to delete this application?'))
            {
                let scope = this;
                let url = '/heritage_financial/delete_application/' + id;
                axios.get(url)
                    .then(response =>
                    {
                        scope.get_applications();
                    })
                    .catch(error =>
                    {
                        console.log(error);
                    });
            }
        },

        email_los(ele, application_id)
        {
            if (confirm('Are you sure you want to email all loan officers?'))
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Sending ... ');

                axios.post('/heritage_financial/email_los/' + application_id)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        let status = response.data.status;
                        if (status == 'success')
                        {
                            notify('success', 'Email sent to LOS');
                        }
                        scope.get_applications();
                    })
                    .catch(function (error)
                    {
                    });
            }

        },
        save_loan_officer(application_id, loan_officer_id)
        {
            let scope = this;

            let formData = new FormData();
            formData.append('application_id', application_id);
            formData.append('loan_officer_id', loan_officer_id);

            axios.post('/heritage_financial/save_loan_officer', formData)
                .then(response =>
                {
                    notify('success', 'Loan Officer Saved');
                    scope.get_applications();

                    alert('YOU MUST NOW ASSIGN THE LOAN OFFICER TO THE APPLICATION IN LENDING PAD');
                })
                .catch(error =>
                {
                    console.log(error);
                });
        },

        assigned_to_lo(application_id, value)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('application_id', application_id);
            formData.append('value', value);

            axios.post('/heritage_financial/assigned_to_lo', formData)
                .then(response =>
                {
                    notify('success', 'Assigned to LO Updated');
                })
                .catch(error =>
                {
                    console.log(error);
                });
        }
    }

}
if (document.URL.match(/sign_acknowledgement/))
{
    window.sign_acknowledgement = function ()
    {

        return {

            show_disabled: false,

            init()
            {

                let scope = this;
                // scope.scroll_to_enable();

                setTimeout(function ()
                {
                    scope.resize_signature_canvas();
                }, 1000);

                window.addEventListener('resize', function ()
                {
                    scope.resize_signature_canvas();
                });
            },

            scroll_to_enable()
            {
                let scope = this;
                const box = document.getElementById('document_html');
                if (scope.has_overflow(box) == true)
                {
                    box.addEventListener('scroll', (e) =>
                    {
                        const scrollableHeight = box.scrollHeight - box.clientHeight - 100
                        if (box.scrollTop >= scrollableHeight)
                        {
                            scope.show_disabled = false;
                        }
                    });
                } else
                {
                    scope.show_disabled = false;
                }
            },

            save_signed_acknowledgement(ele)
            {
                let canvas = document.querySelector('canvas');
                let signature = canvas.toDataURL();
                let blank = document.getElementById('blank_canvas').toDataURL();

                let input_read = document.getElementById('confirm_read_checkbox');
                let input_id = document.getElementById('confirm_id_checkbox');

                let recipient_id = document.getElementById('recipient_id').value;

                document.querySelectorAll('.error').forEach(function (div)
                {
                    div.classList.add('hidden');
                });

                if (!input_read.checked)
                {
                    alert('Please confirm you have read the agreement.');
                    document.querySelector('.confirm-read').classList.remove('hidden');
                    return false;
                }
                if (!input_id.checked)
                {
                    alert('Please confirm your name and email are correct.');
                    document.querySelector('.confirm-name').classList.remove('hidden');
                    return false;
                }
                if (signature == blank)
                {
                    alert('Please sign your name to continue');
                    return false;
                }

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Submitting ... ');

                let formData = new FormData();
                formData.append('recipient_id', recipient_id);
                formData.append('signature', signature);

                axios.post('/agents/acknowledgements/sign_acknowledgement/save_signed_acknowledgement', formData)
                    .then(function (response)
                    {
                        document.querySelector('.unsigned').classList.add('hidden');
                        document.querySelector('.signed').classList.remove('hidden');
                        if (response.data.error)
                        {
                            notify('error', response.data.error);
                        }
                    })
                    .catch(function (error)
                    {
                    });
            },

            resend_signed_copy(ele, recipient_id)
            {
                show_loading_button(ele, 'Sending Copy ... ');
                let formData = new FormData();
                formData.append('recipient_id', recipient_id);

                axios.post('/acknowledgements/resend_completed', formData)
                    .then(function (response)
                    {
                        if (response.data.error)
                        {
                            notify('error', response.data.error);
                            return;
                        }
                        ele.innerHTML = 'Copy Sent Successfully <i class="fa-light fa-check ml-2"></i>';
                        notify('success', 'A Copy Was Successfully Sent');
                    })
                    .catch(function (error)
                    {
                    });
            },

            has_overflow(element)
            {
                return element.scrollHeight != Math.max(element.offsetHeight, element.clientHeight)
            },

            resize_signature_canvas()
            {
                let window_width = window.innerWidth;
                let signature_canvas = document.querySelector('#signature_canvas');
                let blank_canvas = document.querySelector('#blank_canvas');
                let signature_clear = document.querySelector('#signature_clear');
                let width = '';
                if (window_width < 700)
                {
                    width = Math.floor(window_width * 0.9);
                } else
                {
                    width = '685';
                }

                signature_canvas.setAttribute('width', width);
                blank_canvas.setAttribute('width', width);
                signature_clear.style.width = width + 'px';

            },


        }

    }

}